import * as React from "react";

import Table from "components/UI/Table";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import { Prize } from "types/Prize";
import usePrizeServices from "services/PrizeService";
import { useAuth } from "AuthProvider";
import { ROLES } from "types/User";

const PrizeList: React.FC = () => {
  const navigate = useNavigate();
  const [{ prizesService, organizationPrizesService }] = usePrizeServices();
  const [limit, setLimit] = React.useState<number>(25);
  const { session } = useAuth();

  const onNewTicketHanlder = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_PRIZE.path);
  };

  const onEditTicketHandler = async (prize: Prize) => {
    navigate(
      replaceParam(ROUTE_DEFINITIONS.EDIT_PRIZE.path, "uuid", prize.uuid)
    );
  };

  return (
    <div className="h-full w-full">
      <Table
        title="Prizes list"
        limit={limit}
        columns={[
          { title: "Title", field: "title" },
          { title: "Value", field: "value" },
          { title: "Organization", field: "organization.name" },
          {
            title: "Image",
            render: (rowData: Prize) => (
              <img src={rowData.imageUrl} className="w-full" />
            ),
          },
        ]}
        data={async (query: any) => {
          const isOrg = session?.user?.roles?.includes(ROLES.ORG);
          const service = isOrg ? organizationPrizesService : prizesService;
          const res = await service({
            input: {
              page: query.page + 1,
              query: {
                title: {
                  type: "regex",
                  value: query.search,
                },
              },
            },
          });

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Edit Ticket",
            onClick: (_, rowData: Prize) => onEditTicketHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Add Ticket",
            isFreeAction: true,
            onClick: onNewTicketHanlder,
          },
        ]}
      />
    </div>
  );
};

export default PrizeList;
