import * as React from "react";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";

import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { toast } from "react-toastify";
import { Organization } from "types/Organization";
import { Prize } from "types/Prize";
import FileUpload from "components/UI/FileUpload";
import useSettingsService from "services/SettingsService";
import { Settings } from "types/Settings";
import { Switch } from "@material-ui/core";
import useOrganizationServices from "services/OrganizationService";
import { useAuth } from "AuthProvider";
import { ROLES } from "types/User";
import useAuthServices from "services/AuthService";

const Settings: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [{ settingsService, updateSettingsService }, settingsServieLoading] =
    useSettingsService();
  const [{ updateOriganizationService, organizationService }] =
    useOrganizationServices();
  const { tokenLoginService } = useAuthServices();
  const [settings, setSettings] = React.useState<Settings>(null);
  const { session } = useAuth();
  const [organization, setOrganization] = React.useState(null);
  const [removeGuestUsers, setRemoveGuestUsers] = React.useState(null);
  const [removeGuestUsersAfterDays, setRemoveGuestUsersAfterDays] =
    React.useState(null);

  const [allowGrandPrizeRaffleCreation, setAllowGrandPrizeRaffleCreation] =
    React.useState(false);
  const [authGrandPrizeLinkAuto, setAuthGrandPrizeLinkAuto] =
    React.useState(false);

  const fetchSettings = React.useCallback(async () => {
    const isOrg = session?.user?.roles?.includes(ROLES.ORG);

    if (isOrg) {
      const result = await tokenLoginService();
      const org = result.user.organization;
      setOrganization(org);
      if (org) {
        const { settings } = org;
        setAllowGrandPrizeRaffleCreation(
          settings.allowGrandPrizeRaffleCreation
        );
        setAuthGrandPrizeLinkAuto(settings.authGrandPrizeLinkAuto);
      }
    } else {
      const { doc } = await settingsService();
      const t = doc as Settings;

      setRemoveGuestUsers(t.removeGuestUsers || false);
      setRemoveGuestUsersAfterDays(t.removeGuestUsersAfterDays || 1);

      setSettings(t);
    }
  }, []);

  React.useEffect(() => {
    fetchSettings();
  }, []);

  const onSubmitHandler = async () => {
    const isOrg = session?.user?.roles?.includes(ROLES.ORG);

    if (isOrg) {
      let input = {
        name: organization.name,
        details: organization.details,
        userUUID: session.user.uuid,
        location: organization.location,
        settings: {
          allowGrandPrizeRaffleCreation,
          authGrandPrizeLinkAuto,
        },
        uuid: session?.user?.organization?.uuid,
      };
      let res = await updateOriganizationService({ input });
      toast.success(res.message);
    } else {
      let input = {
        removeGuestUsers,
        removeGuestUsersAfterDays: Number(removeGuestUsersAfterDays || 0),
      };
      let res = await updateSettingsService({
        input,
      });
      toast.success(res.message);
    }
  };

  return (
    <div className="flex flex-col bg-white rounded h-full">
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">Settings</h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              {session.user.roles &&
              session.user.roles.includes(ROLES.ADMIN) ? (
                <div className="flex flex-col">
                  <p className="text-xs text-gray-400 capitalize">
                    Remove guest users
                  </p>
                  <div className="rounded shadow-sm -space-y-px">
                    <div>
                      <Switch
                        checked={removeGuestUsers}
                        onChange={(e) => setRemoveGuestUsers(e.target.checked)}
                      />
                    </div>
                  </div>
                  {removeGuestUsers && (
                    <div className="rounded shadow-sm -space-y-px mt-5">
                      <p className="text-xs text-gray-400 capitalize">
                        Remove guest users after days:
                      </p>
                      <div>
                        <input
                          id="value"
                          name="value"
                          type="number"
                          min={0}
                          value={removeGuestUsersAfterDays}
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                          placeholder="Days"
                          onChange={(e) =>
                            setRemoveGuestUsersAfterDays(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="flex items-center gap-2 mt-2">
                    <input
                      type="checkbox"
                      name="active"
                      checked={authGrandPrizeLinkAuto}
                      className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                      onChange={() =>
                        setAuthGrandPrizeLinkAuto(!authGrandPrizeLinkAuto)
                      }
                    />
                    <span className="text-xs text-gray-400 capitalize">
                      Authorize grand prizes link automatically
                    </span>
                  </div>
                </>
              )}

              <div className="w-1/2 flex rounded shadow-sm ml-auto">
                <button
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                  onClick={() => navigation(ROUTE_DEFINITIONS.PRIZE.path)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={settingsServieLoading}
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                >
                  Accept
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
