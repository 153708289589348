import * as React from "react";

import Table from "components/UI/Table";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import { Organization } from "types/Organization";
import useOrganizationServices from "services/OrganizationService";
import StatComponent from "components/UI/StatComponent";

const OrganizationList: React.FC = () => {
  const navigate = useNavigate();
  const [{ organizationsService, organizationStatsService }] =
    useOrganizationServices();
  const [limit, setLimit] = React.useState<number>(25);
  const [stats, setStats] = React.useState([]);
  const [selectedStats, setSelectedStats] = React.useState([]);
  const tableRef = React.useRef(null);

  const onNewOrganizationHanlder = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_ORGANIZATION.path);
  };

  const onEditOrganizationHandler = async (organization: Organization) => {
    navigate(
      replaceParam(
        ROUTE_DEFINITIONS.EDIT_ORGANIZATION.path,
        "uuid",
        organization.uuid
      )
    );
  };

  React.useEffect(() => {
    organizationStatsService().then((data) => {
      console.warn("data", data);
      setStats(
        data.doc.map((stat: any) => {
          return {
            collection: stat.title,
            value: stat.count,
            filter: stat.filter,
          };
        })
      );
    });
  }, []);

  React.useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [selectedStats]);

  const loadData = async (query: any) => {
    const res = await organizationsService({
      input: {
        page: query.page + 1,
        query: {
          $and: [
            { name: { type: "regex", value: query.search } },
            selectedStats?.length ? { $or: selectedStats } : {},
          ],
        },
      },
    });

    setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

    return {
      data: res.docs,
      page: res.page - 1,
      totalCount: res.totalDocs,
    };
  };

  return (
    <div className="h-full w-full">
      <div className="flex flex-row w-full flex-wrap gap-5 my-2">
        {stats?.map((stat: any) => (
          <StatComponent
            {...stat}
            isSelectable={true}
            isSelected={selectedStats?.includes(stat.filter)}
            onSelect={(filter: any) => {
              if (selectedStats?.includes(filter)) {
                setSelectedStats(selectedStats.filter((f: any) => f != filter));
              } else {
                setSelectedStats([...selectedStats, filter]);
              }
            }}
          />
        ))}
      </div>
      <Table
        title="Organization list"
        limit={limit}
        ref={tableRef}
        columns={[
          { title: "Name", field: "name" },
          { title: "Details", field: "details" },
          {
            title: "User",
            render: (rowData: Organization) =>
              rowData.user ? (
                <span
                  onClick={() =>
                    navigate(
                      replaceParam(
                        ROUTE_DEFINITIONS.EDIT_USER.path,
                        "uuid",
                        rowData?.user?.uuid
                      )
                    )
                  }
                >
                  {rowData?.user?.username}
                </span>
              ) : (
                "N/A"
              ),
          },
        ]}
        data={loadData}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Edit Organization",
            onClick: (_, rowData: Organization) =>
              onEditOrganizationHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Add Organization",
            isFreeAction: true,
            onClick: onNewOrganizationHanlder,
          },
        ]}
      />
    </div>
  );
};

export default OrganizationList;
