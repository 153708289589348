import * as React from 'react';
import Select from 'react-select';

import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_DEFINITIONS } from 'types/Routes';
import { toast } from 'react-toastify';
import { Qr, QR_TYPE } from 'types/Qr';
import useQrServices from 'services/QrService';

const ManageQr: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [{ qrService, createQrService, updateQrService }, qrServieLoading] =
    useQrServices();
  const [type, setType] =
    React.useState<{ label: QR_TYPE; value: QR_TYPE }>(null);
  const [qr, setQr] = React.useState<Qr>(null);

  const fetchOrganization = React.useCallback(async () => {
    if (uuid && !qr) {
      const { doc } = await qrService({ uuid });
      const t = doc as Qr;

      setType({ label: t.type, value: t.type });

      setQr(t);
    }
  }, [uuid, qr]);

  React.useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  const onSubmitHandler = async () => {
    const input = {
      type: type.value,
    };
    let res;

    if (!uuid) {
      res = await createQrService({ input });
      navigation(ROUTE_DEFINITIONS.QR.path);
    } else {
      res = await updateQrService({
        input: {
          ...input,
          uuid: qr.uuid,
        },
      });
    }

    toast.success(res.message);
  };

  return (
    <div className="flex flex-col bg-white rounded h-full">
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">{uuid ? 'Edit qr' : 'New qr'}</h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  QR information
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="type" className="sr-only">
                      Qr
                    </label>
                    <Select
                      id="type"
                      name="type"
                      placeholder="Type"
                      classNamePrefix="select"
                      value={type}
                      options={Object.values(QR_TYPE).map((t) => ({
                        label: t,
                        value: t,
                      }))}
                      onChange={(type) => setType(type as any)}
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2 flex rounded shadow-sm ml-auto">
                <button
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                  onClick={() => navigation(ROUTE_DEFINITIONS.QR.path)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={qrServieLoading || !type}
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                >
                  Accept
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageQr;
