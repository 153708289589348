import { Organization } from "./Organization";

export enum QR_TYPE {
  RAFFLE = 'raffle',
  TICKET = 'ticket',
}

export interface Qr {
  uuid: string;
  code: string;
  assigned: boolean;
  type: QR_TYPE;
  organization: Organization;
}

export interface CreateQr {
  input: {
    type: QR_TYPE;
  };
}

export interface UpdateQr {
  input: {
    uuid: string;
    type: QR_TYPE;
  };
}

export interface GeneratePDF {
  input: Pick<Qr, 'uuid'> & {
    templateUUID: string;
    values: [string, string][];
  };
}


export type GetQr = Pick<Qr, 'uuid'>;

export interface QrsInput {
  uuid: string;
}
