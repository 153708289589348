import { ROLES } from './User';

export const ROUTE_DEFINITIONS = {
  LOGIN: {
    key: 'login',
    name: 'Login',
    path: '/login',
  },
  DASHBOARD: {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
  },
  USERS: {
    key: 'users',
    name: 'Users',
    path: '/users',
  },
  NEW_USER: {
    key: 'new-user',
    name: 'New user',
    path: '/users/new',
  },
  EDIT_USER: {
    key: 'edit-user',
    name: 'Edit user',
    path: '/users/:uuid',
  },
  TEAM: {
    key: 'teams',
    name: 'Teams',
    path: '/teams',
  },
  NEW_TEAM: {
    key: 'new-team',
    name: 'New team',
    path: '/teams/new',
  },
  EDIT_TEAM: {
    key: 'edit-team',
    name: 'Edit team',
    path: '/teams/:uuid',
  },
  ORGANIZATION: {
    key: 'organization',
    name: 'Orgs',
    path: '/organizations',
  },
  NEW_ORGANIZATION: {
    key: 'new-organization',
    name: 'New organization',
    path: '/organizations/new',
  },
  EDIT_ORGANIZATION: {
    key: 'edit-organization',
    name: 'Edit organization',
    path: '/organizations/:uuid',
  },
  QR: {
    key: 'qr',
    name: 'Qr',
    path: '/qrs',
  },
  NEW_QR: {
    key: 'new-qr',
    name: 'New qr',
    path: '/qrs/new',
  },
  EDIT_QR: {
    key: 'edit-qr',
    name: 'Edit qr',
    path: '/qrs/:uuid',
  },
  EVENT: {
    key: 'event',
    name: 'Events',
    path: '/events',
  },
  NEW_EVENT: {
    key: 'new-event',
    name: 'New event',
    path: '/events/new',
  },
  EDIT_EVENT: {
    key: 'edit-event',
    name: 'Edit event',
    path: '/events/:uuid',
  },
  PRIZE: {
    key: 'prize',
    name: 'Prizes',
    path: '/prizes',
  },
  NEW_PRIZE: {
    key: 'new-prize',
    name: 'New prize',
    path: '/prizes/new',
  },
  EDIT_PRIZE: {
    key: 'edit-prize',
    name: 'Edit prize',
    path: '/prizes/:uuid',
  },
  RAFFLE: {
    key: 'raffle',
    name: 'Raffles',
    path: '/raffles',
  },
  NEW_RAFFLE: {
    key: 'new-raffle',
    name: 'New raffle',
    path: '/raffles/new',
  },
  FILTER_GRAND_PRIZE: {
    key: 'filter-raffle',
    name: 'Filter raffle',
    // path: '/raffles/filter',
    path: '/raffles/filter/:raffle',

  },
  EDIT_RAFFLE: {
    key: 'edit-raffle',
    name: 'Edit raffle',
    path: '/raffles/:uuid',
  },
  TEMPLATE: {
    key: 'template',
    name: 'Templates',
    path: '/templates',
  },
  NEW_TEMPLATE: {
    key: 'new-template',
    name: 'New template',
    path: '/templates/new',
  },
  EDIT_TEMPLATE: {
    key: 'edit-template',
    name: 'Edit template',
    path: '/templates/:uuid',
  },
  TEMPLATE_EDITOR: {
    key: 'template-editor',
    name: 'Template editor',
    path: '/templates/editor/:uuid',
  },
  SETTINGS: {
    key: 'settings',
    name: 'Settings',
    path: '/settings',
  }
};

export interface Route {
  name: string;
  index?: boolean;
  element: JSX.Element;
  path?: string;
  exact?: boolean;
  auth?: boolean;
  children?: Route[];
  roles?: ROLES[];
}
