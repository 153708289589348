import * as React from "react";
import { QrcodeIcon } from "@heroicons/react/outline";
import QRCode from "react-qr-code";
export interface Element {
  type: string;
  value: any;
  name?: string;
  colorHex?: string;
  fontSize?: number;
  qrColor?: string;
  qrBgColor?: string;
  onChange: (e: React.SyntheticEvent) => void;
}

const Element: React.FC<Element> = ({ type, ...props }) => {
  const { value, colorHex, fontSize, qrColor, qrBgColor, ...rest } = props;
  if (type.includes("qr")) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <label className="flex w-full h-full flex-col">
          <div className="flex w-full h-full flex-col items-center justify-center">
            <QRCode
              className="w-full h-full"
              size={258}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              viewBox={`0 0 256 256`}
              bgColor={qrBgColor ?? "transparent"}
              fgColor={qrColor ?? "black"}
              value={value}
            />
          </div>
        </label>
      </div>
    );
  }

  switch (type) {
    case "textarea":
      return React.createElement(
        "textarea",
        {
          type,
          placeholder: type,
          style: {
            color: colorHex,
            fontSize: fontSize ?? 12,
          },
          value,
          className:
            "h-full w-full border-0 bg-transparent focus:outline-none placeholder:text-black placeholder:text-center placeholder:capitalize",
          ...rest,
        },
        null
      );
    case "text":
      return React.createElement(
        "input",
        {
          type,
          value,
          placeholder: type,
          style: {
            color: colorHex,
            fontSize: fontSize ?? 12,
          },
          className:
            "w-full border-0 bg-transparent focus:outline-none placeholder:text-black placeholder:text-center placeholder:capitalize",
          ...rest,
        },
        null
      );
    case "image":
      return value ? (
        <img className="h-full" src={value} {...rest} />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <label className="flex w-full h-full p-4 flex-col border-4 border-dashed bg-gray-100 border-gray-300">
            <div className="flex w-full h-full flex-col items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full text-gray-400 group-hover:text-gray-600"
                style={{
                  minHeight: 30,
                  minWidth: 30,
                }}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input type="file" className="opacity-0" {...rest} />
          </label>
        </div>
      );
    default:
      return null;
      break;
  }
};

export default Element;
