import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { Pagination, PaginationParams } from 'types/Pagination';
import Response from 'types/Response';
import { CreateRaffle, GetRaffle, Raffle, UpdateGrandPrizeSettingsInput, UpdateRaffle } from 'types/Raffle';

const GET_RAFFLES = gql`
  query raffles($input: BasePaginationInput!) {
    raffles(input: $input) {
      docs {
        ... on Raffle {
          uuid
          status
          active
          title
          type
          organization {
            uuid
            name
          }
          isGrandPrize
          grandPrizeSettings {
            grandPrize {
              uuid
              title
              description
            }
            status
            linkedAt
          }
          participants {
            ... on User {
              uuid
            }
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_RAFFLE = gql`
  query raffle($uuid: String!) {
    raffle(uuid: $uuid) {
      message
      doc {
        ... on Raffle {
          uuid
          status
          active
          type
          title
          description
          startDate
          endDate
          location {
            type
            coordinates
          }
          winners {
            pickedUpAt
            user {
              email
              username
              profile {
                firstname
                lastname
              }
            }
            pickedUpAt
            selectedAt
          }
          settings {
            runAt
            claimSeconds
            winners
            timeZone
            collection {
              date
              location {
                type
                coordinates
              }
            }
          }
          team {
            uuid
            name
          }
          organization {
            uuid
            name
          }
          prizes
          qr {
            uuid
            code
          }
          event {
            uuid
            title
          }
          isGrandPrize
          grandPrizeSettings {
            grandPrize {
              uuid
              title
              description
            }
            status
            linkedAt
          }
        }
      }
    }
  }
`;

const CREATE_RAFFLE = gql`
  mutation createRaffle($input: CreateRaffleInput!) {
    createRaffle(input: $input) {
      doc {
        ... on Raffle {
          uuid
          status
          active
          type
        }
      }
      message
    }
  }
`;

const UPDATE_RAFFLE = gql`
  mutation updateRaffle($input: UpdateRaffleInput!) {
    updateRaffle(input: $input) {
      doc {
        ... on Raffle {
          uuid
          status
          active
          type
        }
      }
      message
    }
  }
`;

const GET_RAFFLE_STATS = gql`
  query raffleStats {
    raffleStats {
      doc
      message
    }
  }
`;

const UPDATE_GRAND_PRIZE_SETTINGS = gql`
  mutation UpdateGrandPrizeSettings($input: UpdateGrandPrizeSettingsInput!) {
    updateGrandPrizeSettings(input: $input) {
      message
    }
  }
`
const GET_ORGANIZATION_RAFFLES = gql`
  query organizationRaffles($input: BasePaginationInput!) {
    organizationRaffles(input: $input) {
      docs {
        ... on Raffle {
          uuid
          status
          active
          title
          type
          organization {
            uuid
            name
          }
          isGrandPrize
          grandPrizeSettings {
            grandPrize {
              uuid
              title
              description
            }
            status
            linkedAt
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const DELETE_RAFFLE = gql`
  mutation cancelRaffle($uuid: String!) {
    cancelRaffle(uuid: $uuid) {
      message
    }
  }
`;

type RaffleServices = [services: Services, loading?: boolean];

interface Services {
  rafflesService: (input: PaginationParams) => Promise<Pagination<Raffle>>;
  raffleService: (input: GetRaffle) => Promise<Response>;
  createRaffleService: (input: CreateRaffle) => Promise<Response>;
  updateRaffleService: (input: UpdateRaffle) => Promise<Response>;
  updateGrandPrizeSettings: (input: UpdateGrandPrizeSettingsInput) => Promise<Response>;
  organizationRafflesService: (
    input: PaginationParams,
  ) => Promise<Pagination<Raffle>>;
  raffleStatsService: () => Promise<Response>;
  deleteRaffleService: (uuid: String) => Promise<Response>;
}

const useRaffleServices = (): RaffleServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const rafflesService = (
    input: PaginationParams,
  ): Promise<Pagination<Raffle>> => {
    setLoading(true);
    return client
      .query({
        query: GET_RAFFLES,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const rafflesData = data.raffles;
        setLoading(false);

        return {
          ...rafflesData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const raffleService = ({ uuid }: GetRaffle): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_RAFFLE,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const raffleData = data.raffle;
        setLoading(false);

        return {
          ...raffleData,
        };
      });
  };

  const createRaffleService = (input: CreateRaffle): Promise<Response> => {
    return client
      .mutate({
        mutation: CREATE_RAFFLE,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const createRaffleData = data.createRaffle;

        return {
          ...createRaffleData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const deleteRaffleService = (uuid: string): Promise<Response> => {
    setLoading(true);
    return client
      .mutate({
        mutation: DELETE_RAFFLE,
        variables: {
          uuid,
        },
      })
      .then(({ data }) => {
        const delRaffleresponse = data.cancelRaffle;

        return {
          ...delRaffleresponse,
        };
      })
      .catch((err) => ({
        error: err,
      })).finally(() => setLoading(false));
  };

  const updateRaffleService = (input: UpdateRaffle): Promise<Response> => {
    return client
      .mutate({
        mutation: UPDATE_RAFFLE,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updateRaffleData = data.updateRaffle;

        return {
          ...updateRaffleData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const updateGrandPrizeSettings = (input: UpdateGrandPrizeSettingsInput): Promise<Response> => {
    return client
      .mutate({
        mutation: UPDATE_GRAND_PRIZE_SETTINGS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }) => {
        const updateGrandPrizeSettings = data.updateGrandPrizeSettings;

        return {
          ...updateGrandPrizeSettings,
        };
      })
      .catch((err) => ({
        error: err,
      }));

  };

  const organizationRafflesService = (
    input: PaginationParams,
  ): Promise<Pagination<Raffle>> => {
    setLoading(true);
    return client
    .query({
      query: GET_ORGANIZATION_RAFFLES,
      variables: {
        ...input,
      },
    })
    .then((res) => deepCopy(res))
    .then(({ data }): any => {
      const rafflesData = data.organizationRaffles;
      setLoading(false);

      return {
        ...rafflesData,
      };
    })
    .catch(() => ({ docs: [] }));
  }

  const raffleStatsService = (): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_RAFFLE_STATS,
      })
      .then(({ data }) => {
        setLoading(false);
        const stats = data.raffleStats;

        return {
          ...stats,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  return [
    {
      rafflesService,
      raffleService,
      createRaffleService,
      updateRaffleService,
      organizationRafflesService,
      updateGrandPrizeSettings,
      raffleStatsService,
      deleteRaffleService
    },
    loading,
  ];
};

export default useRaffleServices;
