import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { Pagination, PaginationParams } from 'types/Pagination';
import Response from 'types/Response';
import {
  CreateUser,
  User,
  UpdateUser,
  GetUser,
  RolesPermissions,
} from 'types/User';

const GET_USERS = gql`
  query users($input: BasePaginationInput!) {
    users(input: $input) {
      docs {
        ... on User {
          uuid
          username
          active
          roles
          permissions
          email
          verified
          profile {
            firstname
            lastname
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_USER_STATS = gql`
  query userStats {
    userStats {
      doc
      message
    }
  }
`;

const GET_USER = gql`
  query user($uuid: String!) {
    user(uuid: $uuid) {
      message
      doc {
        ... on User {
          uuid
          username
          active
          roles
          permissions
          email
          verified
          organization {
            uuid
            name
            details
            settings {
              allowGrandPrizeRaffleCreation
              authGrandPrizeLinkAuto
            }
          }
          profile {
            firstname
            lastname
          }
        }
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      doc {
        ... on User {
          uuid
          username
          active
          roles
          permissions
          email
          verified
          profile {
            firstname
            lastname
          }
        }
      }
      message
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      doc {
        ... on User {
          uuid
          username
          active
          roles
          permissions
          email
          verified
          profile {
            firstname
            lastname
          }
        }
      }
      message
    }
  }
`;

const GET_USER_ROLES_PERMISSIONS = gql`
  query getUserRolesPermissions($uuid: String!) {
    getUserRolesPermissions(uuid: $uuid) {
      roles
      permissions
    }
  }
`;

type UserServices = [services: Services, loading?: boolean];

interface Services {
  usersService: (input: PaginationParams) => Promise<Pagination<User>>;
  userService: (input: GetUser) => Promise<Response>;
  createUserService: (input: CreateUser) => Promise<Response>;
  updateUserService: (input: UpdateUser) => Promise<Response>;
  getUserRolesPermissionsService: (
    input: Pick<User, 'uuid'>,
  ) => Promise<RolesPermissions>;
  userStatsService: () => Promise<Response>;
}

const useUserServices = (): UserServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const usersService = (input: PaginationParams): Promise<Pagination<User>> => {
    setLoading(true);
    return client
      .query({
        query: GET_USERS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const usersData = data.users;
        setLoading(false);

        return {
          ...usersData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const userStatsService = (): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_USER_STATS,
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const usersData = data.userStats;
        setLoading(false);
        return {
          ...usersData,
        };
      })
      .catch(() => ({ docs: [] }));
  }; 

  const userService = ({ uuid }: GetUser): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_USER,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const userData = data.user;
        setLoading(false);

        return {
          ...userData,
        };
      });
  };

  const createUserService = (input: CreateUser): Promise<Response> => {
    return client
      .mutate({
        mutation: CREATE_USER,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const userInfo = data.createUser;

        return {
          ...userInfo,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const updateUserService = (input: UpdateUser): Promise<Response> => {
    return client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const userInfo = data.updateUser;

        return {
          ...userInfo,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const getUserRolesPermissionsService = ({
    uuid,
  }: Pick<User, 'uuid'>): Promise<RolesPermissions> => {
    return client
      .query({
        query: GET_USER_ROLES_PERMISSIONS,
        variables: {
          uuid,
        },
      })
      .then(({ data }) => {
        const rolesPermissions = data.getUserRolesPermissions;

        return {
          ...rolesPermissions,
        };
      });
  };

  return [
    {
      usersService,
      userService,
      createUserService,
      getUserRolesPermissionsService,
      updateUserService,
      userStatsService
    },
    loading,
  ];
};

export default useUserServices;
