import * as React from "react";
import { useRef, useEffect } from "react";
import Table from "components/UI/Table";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import useRaffleServices from "services/RaffleService";
import {
  GRAND_PRIZE_STATUS,
  Raffle,
  RAFFLE_STATUS,
  UpdateGrandPrizeSettingsInput,
} from "types/Raffle";
import { Tabs, Tab, CircularProgress } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import { toast } from "react-toastify";

enum RAFFLES_TYPES {
  REGULAR = "REGULAR",
  GRAND_PRIZE = "grand_prize",
}

const RaffleList: React.FC = (route: any) => {
  const { state } = useLocation();
  const { raffle }: any = state;
  const navigate = useNavigate();
  const [{ rafflesService, updateGrandPrizeSettings }, loading] =
    useRaffleServices();
  const [limit, setLimit] = React.useState<number>(25);
  const tableRef = useRef(null);
  const [raffleData, setRaffleData] = React.useState<any>(undefined);
  const [rafflesType, setRafflesType] = React.useState<RAFFLES_TYPES>(
    RAFFLES_TYPES.REGULAR
  );

  const onResolveHandler = async (
    raffle: Raffle,
    status: GRAND_PRIZE_STATUS
  ) => {
    const input: UpdateGrandPrizeSettingsInput = {
      input: {
        grandPrizeSettings: {
          status,
        },
        uuid: raffle.uuid,
      },
    };
    await updateGrandPrizeSettings(input)
      .then((res: any) => {
        toast.success(res?.message);

        const itemIndex = raffleData.data.findIndex(
          (r: Raffle) => r.uuid === input.input.uuid
        );

        const item = raffleData.data[itemIndex];
        item.status = input.input.grandPrizeSettings.status;
        raffleData.data[itemIndex] = item;

        setRaffleData(raffleData);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [rafflesType]);

  const loadRaffles = async (query: any) => {
    const res = await rafflesService({
      input: {
        page: query.page + 1,
        query: {
          title: {
            type: "regex",
            value: query.search,
          },
          status: {
            $in: [RAFFLE_STATUS.ONGOING, RAFFLE_STATUS.UNCOMMENCED],
          },
          "grandPrizeSettings.grandPrize": {
            $exists: true,
          },
        },
      },
    });

    setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);
    const filteredRaffles = res.docs.filter((r: any) => {
      return r?.grandPrizeSettings?.grandPrize?.uuid === raffle.uuid;
    });
    setRaffleData({
      data: filteredRaffles,
      page: res.page - 1,
      totalCount: res.totalDocs,
    });

    return {
      data: filteredRaffles,
      page: res.page - 1,
      totalCount: res.totalDocs,
    };
  };

  return (
    <>
      <div className="h-full w-full relative">
        {loading && (
          <div className="loader">
            <CircularProgress />
          </div>
        )}
        <Table
          title={"Raffles requesting to join: " + raffle.title}
          limit={limit}
          ref={tableRef}
          columns={[
            { title: "Status", field: "status" },
            { title: "Type", field: "type" },
            { title: "Title", field: "title" },
            {
              title: "Participants",
              render: (rowData: Raffle) => rowData.participants.length,
            },
            {
              title: "Organization",
              render: (rowData: Raffle) => (
                <span
                  className=" text-blue-400 cursor-pointer"
                  onClick={() =>
                    navigate(
                      replaceParam(
                        ROUTE_DEFINITIONS.EDIT_ORGANIZATION.path,
                        "uuid",
                        rowData?.organization?.uuid
                      )
                    )
                  }
                >
                  {rowData?.organization?.name}
                </span>
              ),
            },
          ]}
          data={async (query: any) => raffleData ?? loadRaffles(query)}
          actions={[
            (rowData: Raffle) => ({
              icon: "check",
              tooltip: "Accept Raffle",
              iconProps: {
                color:
                  rowData.grandPrizeSettings.status ===
                  GRAND_PRIZE_STATUS.LINKED
                    ? "primary"
                    : "inherit",
              },
              onClick: (_: any) =>
                onResolveHandler(rowData, GRAND_PRIZE_STATUS.LINKED),
            }),
            (rowData: Raffle) => ({
              icon: "close",
              tooltip: "Reject Raffle",
              iconProps: {
                color:
                  rowData.grandPrizeSettings.status ===
                  GRAND_PRIZE_STATUS.UNLINKED
                    ? "secondary"
                    : "inherit",
              },
              onClick: (_: any) =>
                onResolveHandler(rowData, GRAND_PRIZE_STATUS.UNLINKED),
            }),
          ]}
        />
      </div>
    </>
  );
};

export default RaffleList;
