import * as React from 'react';

export const Content: React.FC = ({ children }) => {
  return (
    <div
      className="w-full flex-1 p-4 bg-gray-100 overflow-y-auto"
      style={{
        height: 'calc(100vh - 100px)',
      }}
    >
      {children}
    </div>
  );
};

export default Content;
