import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { TimeZone } from 'types/TimeZone';

const GET_TIMEZONES = gql`
  query timeZones {
    timeZones {
      name
      value
    }
  }
`;

type TimeZoneServices = [services: Services, loading?: boolean];

interface Services {
  timeZoneService: () => Promise<TimeZone[]>;
}

const useTimeZoneServices = (): TimeZoneServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const timeZoneService = (): Promise<TimeZone[]> => {
    setLoading(true);
    return client
      .query({
        query: GET_TIMEZONES,
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const timeZonesData = data.timeZones;
        setLoading(false);

        return timeZonesData;
      })
      .catch(() => ({ docs: [] }));
  };

  return [
    {
      timeZoneService,
    },
    loading,
  ];
};

export default useTimeZoneServices;
