import React from "react";
import { Prize } from "types/Prize";
interface Props {
  prizes?: Prize[];
  winners?: Number;
}

const PrizesList: React.FC<Props> = ({ prizes = [], winners }) => {
  return (
    <>
      {prizes?.map((prize) => (
        <SinglePrize prize={prize} winners={winners} />
      ))}
    </>
  );
};

const SinglePrize = ({ prize, winners }: { prize: Prize; winners: Number }) => {
  return (
    <div className="flex flex-row p-4 border-b-2 border-black-200">
      <img
        src={prize.imageUrl}
        alt={prize.title}
        width={100}
        height={100}
        className="border-5 border-white-200"
      />
      <div className="flex flex-col px-5">
        <strong className="text-black">{prize.title}</strong>
        {/* // background yellow and text white  */}
        <span className="text-sm ">
          Winners:{" "}
          <strong className="bg-yellow-500 rounded text-white px-2">
            {winners}
          </strong>
        </span>
      </div>
    </div>
  );
};

export default PrizesList;
