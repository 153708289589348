import React from 'react';

interface ModalFooterProps {
  children: any;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children, ...props }) => {
  return (
    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { ...props }),
      )}
    </div>
  );
};

export default ModalFooter;
