import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTE_DEFINITIONS, Route as RouteType } from "types/Routes";
import { ROLES } from "types/User";
import { useAuth } from "AuthProvider";
import { Spinner } from "components/UI";

const Login = React.lazy(() => import("components/Login"));
const NotFound = React.lazy(() => import("components/UI/NotFound"));
const User = React.lazy(() => import("components/User"));
const UserList = React.lazy(() => import("components/User/UserList"));
const ManageUser = React.lazy(() => import("components/User/ManageUser"));
const Team = React.lazy(() => import("components/Team"));
const TeamList = React.lazy(() => import("components/Team/TeamList"));
const ManageTeam = React.lazy(() => import("components/Team/ManageTeam"));
const Organization = React.lazy(() => import("components/Organization"));
const Settings = React.lazy(() => import("components/Settings"));
const OrganizationList = React.lazy(
  () => import("components/Organization/OrganizationList")
);
const ManageOrganization = React.lazy(
  () => import("components/Organization/ManageOrganization")
);
const Qr = React.lazy(() => import("components/Qr"));
const QrList = React.lazy(() => import("components/Qr/QrList"));
const ManageQr = React.lazy(() => import("components/Qr/ManageQr"));
const Event = React.lazy(() => import("components/Event"));
const EventList = React.lazy(() => import("components/Event/EventList"));
const ManageEvent = React.lazy(() => import("components/Event/ManageEvent"));
const Prize = React.lazy(() => import("components/Prize"));
const PrizeList = React.lazy(() => import("components/Prize/PrizeList"));
const ManagePrize = React.lazy(() => import("components/Prize/ManagePrize"));
const Raffle = React.lazy(() => import("components/Raffle"));
const RaffleList = React.lazy(() => import("components/Raffle/RaffleList"));
const RaffleRequestsList = React.lazy(
  () => import("components/Raffle/RaffleRequestsList")
);
const ManageRaffle = React.lazy(() => import("components/Raffle/ManageRaffle"));
const Template = React.lazy(() => import("components/Template"));
const TemplateList = React.lazy(
  () => import("components/Template/TemplateList")
);
const ManageTemplate = React.lazy(
  () => import("components/Template/ManageTemplate")
);
const TemplateEditor = React.lazy(
  () => import("components/Template/TemplateEditor")
);
const Dashboard = React.lazy(() => import("components/Dashboard"));

const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const { session } = useAuth();
  const location = useLocation();

  if (!session?.loggedIn && !session?.loginFetched && !session.loading) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={ROUTE_DEFINITIONS.LOGIN.path}
        state={{ from: location }}
        replace
      />
    );
  } else if (session.loading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Spinner
          text="loading ..."
          className="h-12 w-12 text-sm text-gray-400"
        />
      </div>
    );
  }

  return children;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const { session } = useAuth();
  const location = useLocation();

  if (
    session?.loggedIn &&
    session.loginFetched &&
    (location.pathname === ROUTE_DEFINITIONS.LOGIN.path || "/")
  ) {
    return (
      <Navigate
        to={ROUTE_DEFINITIONS.DASHBOARD.path}
        // state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

export const appRoutes: RouteType[] = [
  {
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
    name: ROUTE_DEFINITIONS.LOGIN.name,
    path: ROUTE_DEFINITIONS.LOGIN.path,
  },
  {
    element: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.DASHBOARD.name,
    path: ROUTE_DEFINITIONS.DASHBOARD.path,
    roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
  },
  {
    element: (
      <AuthRoute>
        <User />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.USERS.name,
    path: ROUTE_DEFINITIONS.USERS.path,
    roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
    children: [
      {
        element: (
          <AuthRoute>
            <UserList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.USERS.name,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
      {
        element: (
          <AuthRoute>
            <ManageUser />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_USER.name,
        path: ROUTE_DEFINITIONS.NEW_USER.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageUser />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_USER.name,
        path: ROUTE_DEFINITIONS.EDIT_USER.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Team />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.TEAM.name,
    path: ROUTE_DEFINITIONS.TEAM.path,
    roles: [ROLES.MASTER, ROLES.ADMIN],
    children: [
      {
        element: (
          <AuthRoute>
            <TeamList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.TEAM.name,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageTeam />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_TEAM.name,
        path: ROUTE_DEFINITIONS.NEW_TEAM.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageTeam />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_TEAM.name,
        path: ROUTE_DEFINITIONS.EDIT_TEAM.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Organization />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.ORGANIZATION.name,
    path: ROUTE_DEFINITIONS.ORGANIZATION.path,
    roles: [ROLES.MASTER, ROLES.ADMIN],
    children: [
      {
        element: (
          <AuthRoute>
            <OrganizationList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.ORGANIZATION.name,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageOrganization />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_ORGANIZATION.name,
        path: ROUTE_DEFINITIONS.NEW_ORGANIZATION.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageOrganization />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_ORGANIZATION.name,
        path: ROUTE_DEFINITIONS.EDIT_ORGANIZATION.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Qr />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.QR.name,
    path: ROUTE_DEFINITIONS.QR.path,
    roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
    children: [
      {
        element: (
          <AuthRoute>
            <QrList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.QR.name,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
      {
        element: (
          <AuthRoute>
            <ManageQr />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_QR.name,
        path: ROUTE_DEFINITIONS.NEW_QR.path,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
      {
        element: (
          <AuthRoute>
            <ManageQr />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_QR.name,
        path: ROUTE_DEFINITIONS.EDIT_QR.path,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Event />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.EVENT.name,
    path: ROUTE_DEFINITIONS.EVENT.path,
    roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
    children: [
      {
        element: (
          <AuthRoute>
            <EventList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.EVENT.name,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
      {
        element: (
          <AuthRoute>
            <ManageEvent />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_EVENT.name,
        path: ROUTE_DEFINITIONS.NEW_EVENT.path,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
      {
        element: (
          <AuthRoute>
            <ManageEvent />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_EVENT.name,
        path: ROUTE_DEFINITIONS.EDIT_EVENT.path,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Prize />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.PRIZE.name,
    path: ROUTE_DEFINITIONS.PRIZE.path,
    roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
    children: [
      {
        element: (
          <AuthRoute>
            <PrizeList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.PRIZE.name,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
      {
        element: (
          <AuthRoute>
            <ManagePrize />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_PRIZE.name,
        path: ROUTE_DEFINITIONS.NEW_PRIZE.path,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
      {
        element: (
          <AuthRoute>
            <ManagePrize />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_PRIZE.name,
        path: ROUTE_DEFINITIONS.EDIT_PRIZE.path,
        roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
      },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Raffle />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.RAFFLE.name,
    path: ROUTE_DEFINITIONS.RAFFLE.path,
    roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
    children: [
      {
        element: (
          <AuthRoute>
            <RaffleList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.RAFFLE.name,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageRaffle />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_RAFFLE.name,
        path: ROUTE_DEFINITIONS.NEW_RAFFLE.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageRaffle />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_RAFFLE.name,
        path: ROUTE_DEFINITIONS.EDIT_RAFFLE.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <RaffleRequestsList />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.FILTER_GRAND_PRIZE.name,
        path: ROUTE_DEFINITIONS.FILTER_GRAND_PRIZE.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Template />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.TEMPLATE.name,
    path: ROUTE_DEFINITIONS.TEMPLATE.path,
    roles: [ROLES.MASTER, ROLES.ADMIN],
    children: [
      {
        element: (
          <AuthRoute>
            <TemplateList />
          </AuthRoute>
        ),
        index: true,
        name: ROUTE_DEFINITIONS.TEMPLATE.name,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageTemplate />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.NEW_TEMPLATE.name,
        path: ROUTE_DEFINITIONS.NEW_TEMPLATE.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <ManageTemplate />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.EDIT_TEMPLATE.name,
        path: ROUTE_DEFINITIONS.EDIT_TEMPLATE.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      {
        element: (
          <AuthRoute>
            <TemplateEditor />
          </AuthRoute>
        ),
        name: ROUTE_DEFINITIONS.TEMPLATE_EDITOR.name,
        path: ROUTE_DEFINITIONS.TEMPLATE_EDITOR.path,
        roles: [ROLES.MASTER, ROLES.ADMIN],
      },
      // {
      //   element: (
      //     <AuthRoute>
      //       <TemplateEditor />
      //     </AuthRoute>
      //   ),
      //   name: ROUTE_DEFINITIONS.NEW_PRIZE.name,
      //   path: ROUTE_DEFINITIONS.NEW_PRIZE.path,
      //   roles: [ROLES.MASTER, ROLES.ADMIN],
      // },
      // {
      //   element: (
      //     <AuthRoute>
      //       <TemplateEditor />
      //     </AuthRoute>
      //   ),
      //   name: ROUTE_DEFINITIONS.EDIT_PRIZE.name,
      //   path: ROUTE_DEFINITIONS.EDIT_PRIZE.path,
      //   roles: [ROLES.MASTER, ROLES.ADMIN],
      // },
    ],
  },
  {
    element: (
      <AuthRoute>
        <Settings />
      </AuthRoute>
    ),
    name: ROUTE_DEFINITIONS.SETTINGS.name,
    path: ROUTE_DEFINITIONS.SETTINGS.path,
    roles: [ROLES.MASTER, ROLES.ADMIN, ROLES.ORG],
  },
  {
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
    // exact: true,
    name: "/",
    path: "/",
  },
  {
    element: <NotFound />,
    path: "*",
    name: "/",
  },
];

export const replaceParam = (
  route: string,
  paramName: string,
  paramValue: string
) => {
  return route.replaceAll(`:${paramName}`, paramValue);
};
