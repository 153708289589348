import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'libs/ApolloClient';

import ModalProvider from 'components/UI/Modal/ModalProvider';
import App from './App';

import 'assets/style/App.scss';
import 'assets/style/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import AuthProvider from 'AuthProvider';

ReactDOM.render(
  <ApolloProvider client={apolloClient()}>
    <HashRouter>
      <AuthProvider>
        <ModalProvider>
          <React.Suspense fallback={null}>
            <App />
            <ToastContainer autoClose={2000} />
          </React.Suspense>
        </ModalProvider>
      </AuthProvider>
    </HashRouter>
  </ApolloProvider>,
  document.getElementById('rafflehunterbo'),
);
