import { SideBarItem } from 'components/UI';
import { ROLES } from 'types/User';
import { ROUTE_DEFINITIONS } from './Routes';

export const MenuList: SideBarItem[] = [
  {
    key: ROUTE_DEFINITIONS.DASHBOARD.key,
    label: ROUTE_DEFINITIONS.DASHBOARD.name,
    path: ROUTE_DEFINITIONS.DASHBOARD.path,
    roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
    icon: 'home',
  },
  {
    key: 'administration',
    label: 'Administration',
    icon: 'adjusments',
    roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
    childrens: [
      {
        key: ROUTE_DEFINITIONS.USERS.key,
        label: ROUTE_DEFINITIONS.USERS.name,
        path: ROUTE_DEFINITIONS.USERS.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        icon: 'users',
      },
      {
        key: ROUTE_DEFINITIONS.NEW_USER.key,
        label: ROUTE_DEFINITIONS.NEW_USER.name,
        path: ROUTE_DEFINITIONS.NEW_USER.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EDIT_USER.key,
        label: ROUTE_DEFINITIONS.EDIT_USER.name,
        path: ROUTE_DEFINITIONS.EDIT_USER.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.TEAM.key,
        label: ROUTE_DEFINITIONS.TEAM.name,
        path: ROUTE_DEFINITIONS.TEAM.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        icon: 'userGroup',
      },
      {
        key: ROUTE_DEFINITIONS.NEW_TEAM.key,
        label: ROUTE_DEFINITIONS.NEW_TEAM.name,
        path: ROUTE_DEFINITIONS.NEW_TEAM.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EDIT_TEAM.key,
        label: ROUTE_DEFINITIONS.EDIT_TEAM.name,
        path: ROUTE_DEFINITIONS.EDIT_TEAM.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.ORGANIZATION.key,
        label: ROUTE_DEFINITIONS.ORGANIZATION.name,
        path: ROUTE_DEFINITIONS.ORGANIZATION.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        icon: 'organization',
      },
      {
        key: ROUTE_DEFINITIONS.NEW_ORGANIZATION.key,
        label: ROUTE_DEFINITIONS.NEW_ORGANIZATION.name,
        path: ROUTE_DEFINITIONS.NEW_ORGANIZATION.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EDIT_ORGANIZATION.key,
        label: ROUTE_DEFINITIONS.EDIT_ORGANIZATION.name,
        path: ROUTE_DEFINITIONS.EDIT_ORGANIZATION.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.QR.key,
        label: ROUTE_DEFINITIONS.QR.name,
        path: ROUTE_DEFINITIONS.QR.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        icon: 'qr',
      },
      {
        key: ROUTE_DEFINITIONS.NEW_QR.key,
        label: ROUTE_DEFINITIONS.NEW_QR.name,
        path: ROUTE_DEFINITIONS.NEW_QR.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EDIT_QR.key,
        label: ROUTE_DEFINITIONS.EDIT_QR.name,
        path: ROUTE_DEFINITIONS.EDIT_QR.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EVENT.key,
        label: ROUTE_DEFINITIONS.EVENT.name,
        path: ROUTE_DEFINITIONS.EVENT.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        icon: 'event',
      },
      {
        key: ROUTE_DEFINITIONS.NEW_EVENT.key,
        label: ROUTE_DEFINITIONS.NEW_EVENT.name,
        path: ROUTE_DEFINITIONS.NEW_EVENT.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EDIT_EVENT.key,
        label: ROUTE_DEFINITIONS.EDIT_EVENT.name,
        path: ROUTE_DEFINITIONS.EDIT_EVENT.path,
        roles: [ROLES.ADMIN, ROLES.MASTER],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.PRIZE.key,
        label: ROUTE_DEFINITIONS.PRIZE.name,
        path: ROUTE_DEFINITIONS.PRIZE.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        icon: 'prize',
      },
      {
        key: ROUTE_DEFINITIONS.NEW_PRIZE.key,
        label: ROUTE_DEFINITIONS.NEW_PRIZE.name,
        path: ROUTE_DEFINITIONS.NEW_PRIZE.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EDIT_PRIZE.key,
        label: ROUTE_DEFINITIONS.EDIT_PRIZE.name,
        path: ROUTE_DEFINITIONS.EDIT_PRIZE.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.RAFFLE.key,
        label: ROUTE_DEFINITIONS.RAFFLE.name,
        path: ROUTE_DEFINITIONS.RAFFLE.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        icon: 'raffle',
      },
      {
        key: ROUTE_DEFINITIONS.NEW_RAFFLE.key,
        label: ROUTE_DEFINITIONS.NEW_RAFFLE.name,
        path: ROUTE_DEFINITIONS.NEW_RAFFLE.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.EDIT_RAFFLE.key,
        label: ROUTE_DEFINITIONS.EDIT_RAFFLE.name,
        path: ROUTE_DEFINITIONS.EDIT_RAFFLE.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.TEMPLATE.key,
        label: ROUTE_DEFINITIONS.TEMPLATE.name,
        path: ROUTE_DEFINITIONS.TEMPLATE.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        icon: 'template',
      },
      {
        key: ROUTE_DEFINITIONS.TEMPLATE_EDITOR.key,
        label: ROUTE_DEFINITIONS.TEMPLATE_EDITOR.name,
        path: ROUTE_DEFINITIONS.TEMPLATE_EDITOR.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        hidden: true,
      },
      {
        key: ROUTE_DEFINITIONS.SETTINGS.key,
        label: ROUTE_DEFINITIONS.SETTINGS.name,
        path: ROUTE_DEFINITIONS.SETTINGS.path,
        roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
        icon: 'settings',
      }
    ],
  },
];