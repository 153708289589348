import * as React from "react";
import { useRoutes } from "react-router-dom";
import { MenuList } from "types/UI";
import { appRoutes } from "routing";
import { NavBar, SideBar, Content } from "components/UI";

import { useAuth } from "AuthProvider";
import Breadcrumb from "components/UI/Breadcrumb";

const App: React.FC = () => {
  const { session } = useAuth();
  const routes = useRoutes(appRoutes);
  const [expandedSideBar, setExpandedSideBar] = React.useState<boolean>(true);

  return session?.loggedIn ? (
    <div
      key={session?.loggedIn ? "true" : "false"}
      className="lato relative flex flex-row h-full"
    >
      <SideBar items={MenuList} expandedSideBar={expandedSideBar} />
      <div
        className={`${
          expandedSideBar ? "w-5/6 flex-1" : "w-full"
        } flex flex-col`}
      >
        <NavBar onExpand={() => setExpandedSideBar(!expandedSideBar)} />
        <Breadcrumb />
        <Content>{routes}</Content>
      </div>
    </div>
  ) : (
    <div className="w-full h-full">{routes}</div>
  );
};

export default App;
