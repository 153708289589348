import * as React from 'react';
import lodash, { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';

import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_DEFINITIONS } from 'types/Routes';
import { toast } from 'react-toastify';
import { Event } from 'types/Event';
import useEventServices from 'services/EventService';
import useQrServices from 'services/QrService';
import { Qr } from 'types/Qr';

const ManageEvent: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [
    { eventService, createEventService, updateEventService },
    eventServieLoading,
  ] = useEventServices();
  const [{ validEventQrsService }, qrServieLoading] = useQrServices();
  const [qrs, setQrs] = React.useState<{ label: string; value: Qr }[]>([]);
  const [event, setEvent] = React.useState<Event>(null);

  const fetchEvent = React.useCallback(async () => {
    if (uuid && !event) {
      const { doc } = await eventService({ uuid });
      const t = doc as Event;

      setQrs(t.qrs.map((qr) => ({ label: qr.code, value: qr })));
      setEvent(t);
    }
  }, [uuid, event]);

  React.useEffect(() => {
    fetchEvent();
  }, [fetchEvent, qrs]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setEvent({
      ...event,
      ...lodash.set(event, name, value),
    });
  };

  const onSubmitHandler = async () => {
    const input = {
      title: event.title,
      qrs: qrs.map((qr) => ({ uuid: qr.value.uuid })),
    };
    let res;

    if (!uuid) {
      res = await createEventService({ input });
      navigation(ROUTE_DEFINITIONS.EVENT.path);
    } else {
      res = await updateEventService({
        input: {
          ...input,
          uuid: event.uuid,
        },
      });
    }

    toast.success(res.message);
  };

  const loadValidQrs = React.useCallback(
    debounce(
      (query: string) =>
        validEventQrsService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((qr: Qr) => ({
            label: qr.code,
            value: qr,
          }));
        }),
      500,
    ),
    [],
  );

  return (
    <div className="flex flex-col bg-white rounded h-full">
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">
          {uuid ? 'Edit event' : 'New event'}
        </h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Event information
                </p>
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="title" className="sr-only">
                      Title
                    </label>
                    <input
                      required
                      id="title"
                      name="title"
                      type="text"
                      value={event?.title ?? ''}
                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Title"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Valid Qrs</p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="qrs" className="sr-only">
                      Qrs
                    </label>
                    <AsyncSelect
                      id="qrs"
                      name="qrs"
                      placeholder="Qrs"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      value={qrs}
                      loadOptions={loadValidQrs}
                      onChange={(users: { label: string; value: Qr }[]) =>
                        setQrs(users)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2 flex rounded shadow-sm ml-auto">
                <button
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                  onClick={() => navigation(ROUTE_DEFINITIONS.EVENT.path)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={eventServieLoading || qrServieLoading}
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                >
                  Accept
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEvent;
