import * as React from "react";

import Table from "components/UI/Table";
import { User } from "types/User";
import useUserServices from "services/UserService";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import StatComponent from "components/UI/StatComponent";
import { CheckCircleIcon } from "@heroicons/react/solid";

const UserList: React.FC = () => {
  const navigate = useNavigate();
  const [{ usersService, userStatsService }] = useUserServices();
  const [limit, setLimit] = React.useState<number>(25);
  const [stats, setStats] = React.useState([]);
  const [selectedStats, setSelectedStats] = React.useState([]);
  const tableRef = React.useRef(null);

  const onNewUserHandler = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_USER.path);
  };

  const onEditUserHandler = async (user: User) => {
    navigate(replaceParam(ROUTE_DEFINITIONS.EDIT_USER.path, "uuid", user.uuid));
  };

  React.useEffect(() => {
    userStatsService().then((data) => {
      console.warn("data", data);
      setStats(
        data.doc.map((stat: any) => {
          return {
            collection: stat.title,
            value: stat.count,
            filter: stat.filter,
          };
        })
      );
    });
  }, []);

  React.useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [selectedStats]);

  const loadData = async (query: any) => {
    const res = await usersService({
      input: {
        page: query.page + 1,
        query: {
          $and: [
            {
              username: {
                type: "regex",
                value: query.search,
              },
              email: {
                type: "regex",
                value: query.search,
              },
            },
            selectedStats?.length ? { $or: selectedStats } : {},
          ],
        },
      },
    });

    setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

    return {
      data: res.docs,
      page: res.page - 1,
      totalCount: res.totalDocs,
    };
  };

  return (
    <div className="h-full w-full">
      <div className="flex flex-row w-full flex-wrap gap-5 my-2">
        {stats?.map((stat: any) => (
          <StatComponent
            {...stat}
            isSelectable={true}
            isSelected={selectedStats?.includes(stat.filter)}
            onSelect={(filter: any) => {
              if (selectedStats?.includes(filter)) {
                setSelectedStats(selectedStats.filter((f: any) => f != filter));
              } else {
                setSelectedStats([...selectedStats, filter]);
              }
            }}
          />
        ))}
      </div>
      <Table
        title="User list"
        ref={tableRef}
        limit={limit}
        columns={[
          {
            title: "Verified",
            render: (rowData: User) => {
              return rowData.verified ? (
                <CheckCircleIcon width={24} color="green" />
              ) : null;
            },
          },
          { title: "Email", field: "email" },
          {
            title: "Roles",
            render: (rowData: User) => (
              <div className="w-full space-x-2">
                {rowData?.roles.map((rol) => {
                  return (
                    <span
                      key={rol}
                      className="w-12 rounded bg-gray-400 text-white p-1 text-xs"
                    >
                      {rol}
                    </span>
                  );
                })}
              </div>
            ),
          },
          {
            title: "Permissions",
            render: (rowData: User) => (
              <div className="w-full space-x-2">
                {rowData?.permissions.map((permission) => {
                  return (
                    <span
                      key={permission}
                      className="w-12 rounded bg-gray-400 text-white p-1 text-xs"
                    >
                      {permission}
                    </span>
                  );
                })}
              </div>
            ),
          },
        ]}
        data={loadData}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Edit User",
            onClick: (_, rowData: User) => onEditUserHandler(rowData),
          }),
          // {
          //   icon: "add",
          //   tooltip: "Add User",
          //   isFreeAction: true,
          //   onClick: onNewUserHandler,
          // },
        ]}
      />
    </div>
  );
};

export default UserList;
