import { CheckCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { toBase64 } from "libs";
import * as React from "react";
import ResizableRect from "react-resizable-rotatable-draggable";
import { TemplateElements } from "types/Template";
import Element from "./Element";

export type DynamicElement = TemplateElements & {
  onChange?: (state: DynamicElement) => void;
  onRemove?: (state: DynamicElement) => void;
  currentElements?: DynamicElement[];
};

const DynamicElement: React.FC<DynamicElement> = ({
  onChange,
  onRemove,
  currentElements,
  ...props
}) => {
  const [state, setState] = React.useState({
    ...props,
  });
  const [edit, setEdit] = React.useState<boolean>(false);

  const contentStyle = {
    top: state.top,
    left: state.left,
    width: state.width,
    height: state.height,
    position: "absolute",
    transform: `rotate(${state.rotateAngle}deg)`,
  };

  const handleResize = (style: any, isShiftKey: any, type: any) => {
    const { top, left, width, height } = style;
    setState({
      ...state,
      width: Math.round(width),
      height: Math.round(height),
      top: Math.round(top),
      left: Math.round(left),
    });
    onChange(state);
  };

  const handleRotate = (rotateAngle: number) => {
    const newState = {
      ...state,
      rotateAngle,
    };

    setState(newState);
    onChange(state);
  };

  const handleDrag = (deltaX: number, deltaY: number) => {
    const newState = {
      ...state,
      top: state.top + deltaY,
      left: state.left + deltaX,
    };

    setState(newState);
    onChange(newState);
  };

  const handleValueChange = async (e: React.SyntheticEvent) => {
    const { value, files } = e.currentTarget as HTMLInputElement;
    const newState = {
      ...state,
      value: files ? await toBase64(files[0]) : value,
    };

    setState(newState);
    onChange(newState);
  };

  const handleStyleChange = (e: React.SyntheticEvent) => {
    const { name, value } = e.currentTarget as HTMLInputElement;

    if (name == "name") {
      const isUsed = currentElements?.find((el) => el.name == value);
      if (isUsed) {
        alert("ID already used");
        return;
      }
    }

    const newState = {
      ...state,
      [name]: name === "fontSize" ? parseInt(value) : value,
    };
    console.log(name);
    console.log(value);

    setState(newState);
    onChange(newState);
  };

  return (
    <div className="flex" onDoubleClick={() => setEdit(true)}>
      <div
        className="w-full cursor-pointer flex relative items-center justify-center"
        style={contentStyle as any}
      >
        <Element
          type={props.type}
          onChange={handleValueChange}
          name={props.name}
          value={props.value ?? ""}
          colorHex={props.colorHex}
          fontSize={props.fontSize}
          qrColor={props.qrColor}
          qrBgColor={props.qrBgColor}
        />
        {edit && (
          <div className="flex items-center justify-center absolute left-full space-x-2 mt-5">
            <div className="flex flex-col bg-gray-300 border border-gray-400 p-2 space-y-2">
              {["text", "textarea"].includes(props.type) && (
                <>
                  <label htmlFor={`${props.name}-id`} className="text-xs">
                    ID
                  </label>
                  <input
                    id={`${props.name}-name`}
                    name="name"
                    value={props.name ?? ""}
                    className="rounded border-gray-400 p-2"
                    placeholder="ID of element"
                    onChange={handleStyleChange}
                  />
                  <label htmlFor={`${props.name}-color`} className="text-xs">
                    Color hex code
                  </label>
                  <input
                    id={`${props.name}-color`}
                    name="colorHex"
                    value={props.colorHex ?? ""}
                    className="rounded border-gray-400 p-2"
                    placeholder="#hex"
                    onChange={handleStyleChange}
                  />
                  <label htmlFor={`${props.name}-size`} className="text-xs">
                    Font size
                  </label>
                  <input
                    id={`${props.name}-size`}
                    type="number"
                    name="fontSize"
                    value={props.fontSize ?? ""}
                    className="rounded border-gray-400 p-2"
                    placeholder="Font size"
                    onChange={handleStyleChange}
                  />
                </>
              )}
              {props.type.includes("qr") && (
                <>
                  <label htmlFor={`${props.name}-id`} className="text-xs">
                    ID
                  </label>
                  <input
                    id={`${props.name}-name`}
                    name="name"
                    value={props.name ?? ""}
                    className="rounded border-gray-400 p-2"
                    placeholder="ID of element"
                    onChange={handleStyleChange}
                  />
                  <label htmlFor={`${props.name}-color`} className="text-xs">
                    Color hex code
                  </label>
                  <input
                    id={`${props.name}-color`}
                    name="qrColor"
                    value={props.qrColor ?? ""}
                    className="rounded border-gray-400 p-2"
                    placeholder="#hex"
                    onChange={handleStyleChange}
                  />
                  <label htmlFor={`${props.name}-bgcolor`} className="text-xs">
                    Background hex code
                  </label>
                  <input
                    id={`${props.name}-bgcolor`}
                    name="qrBgColor"
                    value={props.qrBgColor ?? ""}
                    className="rounded border-gray-400 p-2"
                    placeholder="#hex"
                    onChange={handleStyleChange}
                  />
                </>
              )}
              <div className="flex space-x-2 justify-end">
                <TrashIcon
                  width={25}
                  height={25}
                  className="rounded-full bg-white text-emerald-400"
                  onClick={() => onRemove(state)}
                />
                <CheckCircleIcon
                  width={25}
                  height={25}
                  className="rounded-full bg-white text-emerald-400"
                  onClick={() => setEdit(false)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {!edit && (
        <ResizableRect
          top={state.top}
          rotatable
          left={state.left}
          minWidth={20}
          width={state.width}
          minHeight={20}
          height={state.height}
          onDrag={handleDrag}
          onRotate={handleRotate}
          onResize={handleResize}
          zoomable="nw, ne, se, sw"
          rotateAngle={state.rotateAngle}
        />
      )}
    </div>
  );
};

export default DynamicElement;
