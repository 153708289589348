import React from "react";
import { gql, useApolloClient } from "@apollo/client";
import { deepCopy } from "libs";
import { Pagination, PaginationParams } from "types/Pagination";
import Response from "types/Response";
import { CreateQr, GeneratePDF, GetQr, Qr, UpdateQr } from "types/Qr";

const GET_QRS = gql`
  query qrs($input: BasePaginationInput!) {
    qrs(input: $input) {
      docs {
        ... on Qr {
          uuid
          code
          organization {
            uuid
            name
          }
          type
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_ORGANIZATION_QRS = gql`
  query organizationQrs($input: BasePaginationInput!) {
    organizationQrs(input: $input) {
      docs {
        ... on Qr {
          uuid
          code
          assigned
          type
          organization {
            uuid
            name
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_QR = gql`
  query qr($uuid: String!) {
    qr(uuid: $uuid) {
      message
      doc {
        ... on Qr {
          uuid
          code
          assigned
          type
          organization {
            uuid
            name
          }
        }
      }
    }
  }
`;

const GET_VALID_EVENT_QRS = gql`
  query validEventQrs($input: BasePaginationInput!) {
    validEventQrs(input: $input) {
      docs {
        ... on Qr {
          uuid
          code
          assigned
          type
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const CREATE_QR = gql`
  mutation createQr($input: CreateQrInput!) {
    createQr(input: $input) {
      doc {
        ... on Qr {
          uuid
          code
          assigned
          type
        }
      }
      message
    }
  }
`;

const UPDATE_QR = gql`
  mutation updateQr($input: UpdateQrInput!) {
    updateQr(input: $input) {
      doc {
        ... on Qr {
          uuid
          code
          assigned
          type
        }
      }
      message
    }
  }
`;

const PRINT_QR = gql`
  mutation generatePrintFile($input: generatePrintFileInput!) {
    generatePrintFile(input: $input) {
      doc
      message
    }
  }
`;

type QrServices = [services: Services, loading?: boolean];

interface Services {
  qrsService: (input: PaginationParams) => Promise<Pagination<Qr>>;
  validEventQrsService: (input: PaginationParams) => Promise<Pagination<Qr>>;
  qrService: (input: GetQr) => Promise<Response>;
  createQrService: (input: CreateQr) => Promise<Response>;
  updateQrService: (input: UpdateQr) => Promise<Response>;
  organizationQrsService: (input: PaginationParams) => Promise<Pagination<Qr>>;
  printQrService: (input: GeneratePDF) => Promise<Response>;
}

const useQrServices = (): QrServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const qrsService = (input: PaginationParams): Promise<Pagination<Qr>> => {
    setLoading(true);
    return client
      .query({
        query: GET_QRS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const qrsData = data.qrs;
        setLoading(false);

        return {
          ...qrsData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const printQrService = (input: GeneratePDF): Promise<Response> => {
    setLoading(true);
    return client
      .mutate({
        mutation: PRINT_QR,
        variables: {
          ...input,
        },
      })
      .then(({ data }): any => {
        console.log("result", data);
        const result = data.generatePrintFile;
        return result;
      })
      .catch((error) => {
        console.log(error);
        return { message: "Error" };
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const organizationQrsService = (
    input: PaginationParams
  ): Promise<Pagination<Qr>> => {
    setLoading(true);
    return client
      .query({
        query: GET_ORGANIZATION_QRS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const qrsData = data.organizationQrs;
        setLoading(false);

        return {
          ...qrsData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const validEventQrsService = (
    input: PaginationParams
  ): Promise<Pagination<Qr>> => {
    setLoading(true);
    return client
      .query({
        query: GET_VALID_EVENT_QRS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const validEventQrsData = data.validEventQrs;
        setLoading(false);

        return {
          ...validEventQrsData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const qrService = ({ uuid }: GetQr): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_QR,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const qrData = data.qr;
        setLoading(false);

        return {
          ...qrData,
        };
      });
  };

  const createQrService = (input: CreateQr): Promise<Response> => {
    return client
      .mutate({
        mutation: CREATE_QR,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const createQrData = data.createQr;

        return {
          ...createQrData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const updateQrService = (input: UpdateQr): Promise<Response> => {
    return client
      .mutate({
        mutation: UPDATE_QR,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updateQrData = data.updateQr;

        return {
          ...updateQrData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  return [
    {
      qrService,
      qrsService,
      validEventQrsService,
      createQrService,
      updateQrService,
      organizationQrsService,
      printQrService,
    },
    loading,
  ];
};

export default useQrServices;
