import * as React from "react";
import lodash from "lodash";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { ROUTE_DEFINITIONS } from "types/Routes";
import useQrServices from "services/QrService";
import useTemplateServices from "services/TemplateService";
import { Template } from "types/Template";
import { Qr } from "types/Qr";
import FileUpload from "components/UI/FileUpload";
import { MultiSelect } from "react-multi-select-component";
import MessageModal from "components/UI/Message/MessageModal";

const ManageTemplate: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [{ qrsService }, qrsServiceLoading] = useQrServices();
  const [
    {
      templateService,
      createTemplateService,
      updateTemplateService,
      deleteTemplateService,
    },
    templateServiceLoading,
  ] = useTemplateServices();
  const [template, setTemplate] = React.useState<Template>(null);
  const [templateQrs, setTemplateQrs] = React.useState<String[]>([]);
  const [qrs, setQrs] = React.useState<{ label: string; value: string }[]>();
  const [file, setFile] = React.useState<File>(null);
  const [selected, setSelected] = React.useState([]);
  const [defaultTemplate, setDefaultTemplate] = React.useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    message: "",
    title: "",
    onSubmit: () => {},
  });

  const fetchTemplate = React.useCallback(async () => {
    if (uuid && !template) {
      const { doc } = await templateService({ uuid });
      const t = doc as Template;

      setTemplate(t);
      setDefaultTemplate(t.default || false);
      setTemplateQrs(t.qrs?.map((qr) => qr.uuid));

      setSelected(
        t.qrs.map((qr: Qr) => {
          return { label: qr.code, value: qr.uuid };
        })
      );
    }
  }, [uuid, template]);

  React.useEffect(() => {
    getQrs();
    fetchTemplate();
  }, [fetchTemplate, uuid]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setTemplate({
      ...lodash.set(template, name, value),
    });
  };

  const onSubmitHandler = async () => {
    const input = {
      title: template.title,
      file: file,
      default: defaultTemplate,
      qrs: selected.map((v) => {
        return { uuid: v.value };
      }),
    };
    let res;
    if (!uuid) {
      res = await createTemplateService({ input });
      navigation(ROUTE_DEFINITIONS.TEMPLATE.path);
    } else {
      res = await updateTemplateService({
        input: {
          ...input,
          uuid: template.uuid,
        },
      });
    }

    toast.success(res.message);
  };

  const setSelectedValues = (x: any) => {
    setSelected(x);
  };

  const getQrs = () => {
    qrsService({
      input: {
        page: 1,
      },
    }).then(({ docs }) => {
      setQrs(
        docs.map((qr: Qr) => ({
          label: qr.code,
          value: qr.uuid,
        }))
      );
      return docs.map((qr: Qr) => ({
        label: qr.code,
        value: qr,
      }));
    });
  };

  const deleteTemplate = async () => {
    const res = await deleteTemplateService(uuid);
    toast.success(res.message);
    navigation(ROUTE_DEFINITIONS.TEMPLATE.path);
  };

  const showDeleteConfirmation = () => {
    setConfirmDialog({
      message: "Are you sure you want to delete this template?",
      title: "Delete Template",
      onSubmit: deleteTemplate,
    });
    setShowConfirmDialog(true);
  };

  const onFileChange = (file: File) => setFile(file);
  return (
    <div className="flex flex-col bg-white rounded h-full">
      {showConfirmDialog && (
        <MessageModal
          confirm={true}
          title={confirmDialog?.title}
          message={confirmDialog?.message}
          onDismiss={() => setShowConfirmDialog(false)}
          onSubmit={() => confirmDialog?.onSubmit()}
        />
      )}
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">
          {uuid ? "Edit template" : "New template"}
        </h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Team information
                </p>
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="title" className="sr-only">
                      Title
                    </label>
                    <input
                      required
                      id="title"
                      name="title"
                      type="text"
                      value={template?.title ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Title"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Template qrs</p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="roles" className="sr-only">
                      Qrs
                    </label>
                    {qrs && (
                      <MultiSelect
                        options={qrs}
                        value={selected}
                        onChange={setSelectedValues}
                        labelledBy="Select QR's"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-28 flex gap-10">
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    checked={defaultTemplate}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onChange={() => setDefaultTemplate(!defaultTemplate)}
                  />
                  <span className="text-gray-500 font-medium">
                    Default template
                  </span>
                </label>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Template background image
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="organization" className="sr-only">
                      Image
                    </label>
                    <FileUpload
                      title="Upload Image(png,jpeg)"
                      placeholder="Select a photo"
                      variant="image"
                      imageUrl={template?.backgroundUrl ?? ""}
                      onFileChange={onFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                {uuid && (
                  <button
                    className="w-1/4 py-2 px-4 text-base text-white bg-red-500 rounded-l-lg rounded-r-lg hover:bg-red-600 mt-auto"
                    onClick={() => showDeleteConfirmation()}
                  >
                    Delete
                  </button>
                )}
                <div className="w-1/2 flex rounded shadow-sm ml-auto">
                  <button
                    className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                    onClick={() => navigation(ROUTE_DEFINITIONS.TEMPLATE.path)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={templateServiceLoading || qrsServiceLoading}
                    className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTemplate;
