import * as React from "react";
import lodash from "lodash";

import Select, { OptionsType } from "react-select";
import { PERMISSIONS, ROLES, RolesPermissions, User } from "types/User";
import useUserServices from "services/UserService";
import useAuthServices from "services/AuthService";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { toast } from "react-toastify";

const ManageUser: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const { getRolesPermissionsService } = useAuthServices();
  const [{ userService, createUserService, updateUserService }, loading] =
    useUserServices();
  const [roles, setRoles] = React.useState<
    OptionsType<{ label: string; value: string }>
  >([]);
  const [permissions, setPermissions] = React.useState<
    OptionsType<{ label: string; value: string }>
  >([]);
  const [selectedRoles, setSelectedRoles] = React.useState<
    OptionsType<{ label: string; value: string }>
  >([]);
  const [selectedPermissions, setSelectedPermissions] = React.useState<
    OptionsType<{ label: string; value: string }>
  >([]);
  const [isVerified, setIsVerified] = React.useState<boolean>();
  const [user, setUser] = React.useState<User>(null);

  const fetchRolesPermissions = React.useCallback(async () => {
    if (roles.length === 0) {
      const res: RolesPermissions = await getRolesPermissionsService();
      const optionRoles = res.roles.map((r: ROLES) => ({ label: r, value: r }));
      const optionPermissions = res.permissions.map((p: PERMISSIONS) => ({
        label: p,
        value: p,
      }));

      setRoles(optionRoles);
      setPermissions(optionPermissions);
    }
  }, [roles, permissions]);
  const fetchUser = React.useCallback(async () => {
    if (uuid && !user) {
      const { doc } = await userService({ uuid });
      setUser(doc as User);
      setIsVerified(doc.verified);
    }
  }, [uuid, user]);

  React.useEffect(() => {
    fetchRolesPermissions();
    fetchUser();

    if (uuid) {
      setSelectedRoles(user?.roles?.map((r) => ({ label: r, value: r })));
      setSelectedPermissions(
        user?.permissions?.map((p) => ({ label: p, value: p }))
      );
    }
  }, [user, fetchRolesPermissions, fetchUser]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setUser({
      ...user,
      ...lodash.set(user, name, value),
    });
  };

  const onSubmitHandler = async () => {
    let input = {
      username: user.username,
      active: user.active,
      roles: (selectedRoles?.map((r) => r.value) ?? []) as ROLES[],
      permissions: (selectedPermissions?.map((p) => p.value) ??
        []) as PERMISSIONS[],
      email: user.email,
      profile: {
        firstname: user.profile.firstname,
        lastname: user.profile.lastname,
      },
      verified: isVerified,
    };

    let res;

    if (!uuid) {
      res = await createUserService({
        input: { ...input, password: "12345678" },
      });
      navigation(ROUTE_DEFINITIONS.USERS.path);
    } else {
      res = await updateUserService({
        input: {
          ...input,
          uuid: user.uuid,
        },
      });
    }

    toast.success(res.message);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col bg-white rounded h-full">
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">
          {uuid ? "Edit user" : "New user"}
        </h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              autoComplete="off"
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  User information
                </p>
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="username" className="sr-only">
                      Username
                    </label>
                    <input
                      required
                      id="username"
                      name="username"
                      type="text"
                      value={user?.username ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Username"
                      onChange={onInputChange}
                      autoComplete="0"
                    />
                  </div>

                  <div className="rounded shadow-sm -space-y-px">
                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        required
                        id="email"
                        name="email"
                        type="text"
                        value={user?.email ?? ""}
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                        placeholder="Email"
                        onChange={onInputChange}
                        autoComplete="0"
                      />
                    </div>

                    {/* {!uuid && (
                      <div>
                        <label htmlFor="password" className="sr-only">
                          Password
                        </label>
                        <input
                          required={!user}
                          id="password"
                          name="password"
                          type={loading ? "text" : "password"}
                          value={
                            (loading
                              ? user?.password?.replaceAll(/./g, "*")
                              : user?.password) ?? ""
                          }
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                          placeholder="Password"
                          onChange={onInputChange}
                          autoComplete="0"
                        />
                      </div>
                    )} */}
                  </div>
                </div>

                <div className="flex flex-col">
                  <p className="text-xs text-gray-400 capitalize">
                    User profile
                  </p>
                  <div>
                    <label htmlFor="profile.firstname" className="sr-only">
                      Firstname
                    </label>
                    <input
                      required
                      id="profile.firstname"
                      name="profile.firstname"
                      type="text"
                      value={user?.profile?.firstname ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Firstname"
                      onChange={onInputChange}
                      autoComplete="0"
                    />
                  </div>
                  <div>
                    <label htmlFor="profile.lastname" className="sr-only">
                      Lastname
                    </label>
                    <input
                      required
                      id="profile.lastname"
                      name="profile.lastname"
                      type="text"
                      value={user?.profile?.lastname ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Lastname"
                      onChange={onInputChange}
                      autoComplete="0"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  User roles and permissions
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="roles" className="sr-only">
                      Roles
                    </label>
                    <Select
                      id="roles"
                      defaultValue={[]}
                      value={selectedRoles}
                      isMulti
                      name="roles"
                      options={roles}
                      placeholder="Assign roles"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(values) => setSelectedRoles(values)}
                    />
                  </div>

                  <div className="mt-2">
                    <label htmlFor="permissions" className="sr-only">
                      Permisos
                    </label>
                    <Select
                      id="permissions"
                      defaultValue={[]}
                      value={selectedPermissions}
                      isMulti
                      name="permissions"
                      placeholder="Assign permissions"
                      options={permissions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(values) => setSelectedPermissions(values)}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-28">
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    defaultChecked={user?.active}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onClick={() => setUser({ ...user, active: !user?.active })}
                  />
                  <span className="text-gray-500 font-medium">Active</span>
                </label>
              </div>
              <div className="mb-28">
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="verified"
                    defaultChecked={user?.verified}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onClick={() => setIsVerified(!isVerified)}
                  />
                  <span className="text-gray-500 font-medium">Verified</span>
                </label>
              </div>
              <div className="w-1/2 flex rounded shadow-sm ml-auto">
                <button
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                  onClick={() => navigation(ROUTE_DEFINITIONS.USERS.path)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                >
                  Accept
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
