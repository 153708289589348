import * as React from "react";

import Table from "components/UI/Table";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import { Team } from "types/Team";
import useTeamServices from "services/TeamService";

const TeamList: React.FC = () => {
  const navigate = useNavigate();
  const [{ teamsService }] = useTeamServices();
  const [limit, setLimit] = React.useState<number>(25);

  const onNewTeamHandler = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_TEAM.path);
  };

  const onEditTeamHandler = async (team: Team) => {
    navigate(replaceParam(ROUTE_DEFINITIONS.EDIT_TEAM.path, "uuid", team.uuid));
  };

  return (
    <div className="h-full w-full">
      <Table
        title="Team list"
        limit={limit}
        columns={[
          { title: "Name", field: "name" },
          { title: "Code", field: "code" },
          {
            title: "Owner",
            render: (rowData: Team) =>
              rowData.owner ? (
                <span
                  className=" text-blue-400 cursor-pointer"
                  onClick={() =>
                    navigate(
                      replaceParam(
                        ROUTE_DEFINITIONS.EDIT_USER.path,
                        "uuid",
                        rowData?.owner?.uuid
                      )
                    )
                  }
                >
                  {rowData?.owner?.username}
                </span>
              ) : (
                "N/A"
              ),
          },
        ]}
        data={async (query) => {
          const res = await teamsService({
            input: {
              page: query.page + 1,
              query: {
                name: {
                  type: "regex",
                  value: query.search,
                },
              },
            },
          });

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Edit User",
            onClick: (_, rowData: Team) => onEditTeamHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Add User",
            isFreeAction: true,
            onClick: onNewTeamHandler,
          },
        ]}
      />
    </div>
  );
};

export default TeamList;
