import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { toast } from 'react-toastify';

import { Session } from 'types/Auth';

//  Graphql setup.
const httpLinkUpload = createUploadLink({
  uri: process.env.API_URL,
  credentials: 'include',
});

const httpLink = createHttpLink({
  uri: process.env.API_URL,
  credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  let msg: string;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      msg = `${msg || ''} ${message}`;
    });
  }

  if (networkError) {
    msg = `${msg || ''} ${networkError}`;
  }

  if (networkError?.toString().indexOf('500') > -1) {
    removeSession()
  }

  toast.error(msg);
});

const apolloClient = () => {
  const authLink = setContext((_, { headers }) => {
    const session = getSession();
    const token = process.env.TOKEN_LOGIN || session.token

    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return {
      headers: {
        ...headers,
      },
    };
  });

  const httpLinkSwitch = split(
    (operation) => operation.getContext().hasUpload,
    httpLinkUpload,
    httpLink,
  );

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLinkSwitch)]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });

  return client;
};

// Save Token to localStorage
const storeSession = (session: Session) => {
  const sessionString = JSON.stringify(session);

  localStorage.setItem('session', sessionString);
};

const getSession = () => {
  let session: Session;

  try {
    session = JSON.parse(localStorage.getItem('session'));
  } catch (e) {
    console.error(e);
  }

  return session || {};
};  

const removeSession = () => {
  localStorage.removeItem('session');

  if (process.env.TOKEN_LOGIN) {
    localStorage.removeItem('raffleToken');
  }
};

export { storeSession, getSession, apolloClient, removeSession };
