import * as React from "react";
import { toast } from "react-toastify";

export enum FileUploadVariant {
  file = "file",
  image = "image",
}

export type FileUploadVariantTokens = keyof typeof FileUploadVariant;

interface Props {
  title?: string;
  imageUrl?: string;
  placeholder: string;
  variant?: FileUploadVariantTokens;
  onFileChange: (file: File) => void;
}

const getBase64 = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};

const FileUpload: React.FC<Props> = ({
  title,
  placeholder,
  variant,
  imageUrl,
  onFileChange,
}) => {
  const [localFile, setLocalFile] = React.useState<File>(null);
  const [image, setImage] = React.useState<string>(imageUrl);

  const onChange = (e: React.SyntheticEvent) => {
    const element = e.currentTarget as HTMLInputElement;
    const file = element.files[0];
    if (file) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 6) {
        toast.error("La imagen excede el tamaño");
      } else {
        onFileChange(file);
        setLocalFile(file);
        if (FileUploadVariant[variant] === FileUploadVariant.image) {
          getBase64(file).then((imageUrl: string) => setImage(imageUrl));
        }
      }
    }
  };

  return (
    <div className="flex justify-center mt-8">
      <div className="rounded-lg bg-gray-50 lg:w-1/2">
        <div className="m-4">
          <label className="inline-block mb-2 text-gray-500">{title}</label>
          <div className="flex flex-col items-center justify-center w-full">
            <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
              <div className="flex flex-col items-center justify-center pt-7">
                {image ? (
                  <img src={image} className="h-20" />
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {placeholder}
                    </p>
                  </>
                )}
              </div>
              <input
                type="file"
                className="opacity-0"
                accept={
                  FileUploadVariant[variant] === FileUploadVariant.image
                    ? "image/png"
                    : "*"
                }
                onChange={onChange}
              />
            </label>
            {localFile && <p className="py-2 text-sm mt-6">{localFile.name}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
