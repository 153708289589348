import * as React from "react";

import Table from "components/UI/Table";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import useTemplateServices from "services/TemplateService";
import { Template } from "types/Template";
import { useAuth } from "AuthProvider";
import { ROLES } from "types/User";
import { getImg } from "utils/general";

const TemplateList: React.FC = () => {
  const navigate = useNavigate();
  const [{ templatesService, organizationTemplatesService }] =
    useTemplateServices();
  const [limit, setLimit] = React.useState<number>(25);
  const { session } = useAuth();

  const onNewTemplateHandler = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_TEMPLATE.path);
  };

  const onEditTemplateHandler = async (template: Template) => {
    navigate(
      replaceParam(ROUTE_DEFINITIONS.EDIT_TEMPLATE.path, "uuid", template.uuid)
    );
  };

  const onTemplateEditHandler = async (template: Template) => {
    navigate(
      replaceParam(
        ROUTE_DEFINITIONS.TEMPLATE_EDITOR.path,
        "uuid",
        template.uuid
      )
    );
  };

  return (
    <div className="h-full w-full">
      <Table
        title="Template list"
        limit={limit}
        columns={[
          { title: "Title", field: "title" },
          {
            title: "Preview",
            render: (rowData: Template) => {
              return <img className="h-24" src={getImg(rowData.previewUrl)} />;
            },
          },
          {
            title: "Background",
            render: (rowData: Template) => {
              return <img className="h-24" src={rowData.backgroundUrl} />;
            },
          },
          {
            title: "Default",
            render: (rowData: Template) => {
              return rowData.default ? "Yes" : "No";
            },
          },
        ]}
        data={async (query) => {
          const isOrg = session?.user?.roles?.includes(ROLES.ORG);
          const service = isOrg
            ? organizationTemplatesService
            : templatesService;
          const res = await service({
            input: {
              page: query.page + 1,
              query: {
                title: {
                  type: "regex",
                  value: query.search,
                },
              },
            },
          });

          console.log(res);

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit_note",
            tooltip: "Template editor",
            onClick: (_, rowData: Template) => onTemplateEditHandler(rowData),
          }),
          (_) => ({
            icon: "edit",
            tooltip: "Edit Template",
            onClick: (_, rowData: Template) => onEditTemplateHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Add Template",
            isFreeAction: true,
            onClick: onNewTemplateHandler,
          },
        ]}
      />
    </div>
  );
};

export default TemplateList;
