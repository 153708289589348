import * as React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { CalendarIcon } from "@heroicons/react/outline";

import { ROUTE_DEFINITIONS } from "types/Routes";
import usePrizeServices from "services/PrizeService";
import { Organization } from "types/Organization";
import { Prize } from "types/Prize";
import useOrganizationServices from "services/OrganizationService";
import {
  Raffle,
  RaffleWinner,
  RAFFLE_STATUS,
  RAFFLE_TYPE,
  UpdateRaffle,
} from "types/Raffle";
import useEventServices from "services/EventService";
import { Event } from "types/Event";
import { Qr } from "types/Qr";
import useQrServices from "services/QrService";
import useTeamServices from "services/TeamService";
import { Team } from "types/Team";
import useRaffleServices from "services/RaffleService";
import { TimeZone } from "types/TimeZone";
import useTimeZoneServices from "services/TimeZoneService";
import Map from "../UI/Map";
import { Location } from "../UI/Map";
import PrizesList from "components/UI/PrizesList";
import MessageModal from "components/UI/Message/MessageModal";
import { Tab, Tabs } from "@material-ui/core";
import Winners from "components/UI/Winners";
import { User } from "types/User";

const ManageRaffle: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    message: "",
    title: "",
    onSubmit: () => {},
  });
  const [
    { raffleService, updateRaffleService, deleteRaffleService },
    raffleServiceLoading,
  ] = useRaffleServices();
  const [{ timeZoneService }, timeZoneServiceLoading] = useTimeZoneServices();
  const [{ prizesService }, prizeServiceLoading] = usePrizeServices();
  const [{ qrsService }, qrsServiceLoading] = useQrServices();
  const [{ eventsService }, eventsServiceLoading] = useEventServices();
  const [{ teamsService }, teamsServiceLoading] = useTeamServices();
  const [activeTab, setActiveTab] = React.useState<Number>(1);
  const [{ organizationsService }, organizationsServiceLoading] =
    useOrganizationServices();
  const [event, setEvent] = React.useState<{
    label: string;
    value: Event;
  }>(null);
  const [team, setTeam] = React.useState<{
    label: string;
    value: Team;
  }>(null);
  const [organization, setOrganization] = React.useState<{
    label: string;
    value: Organization;
  }>(null);
  const [prizes, setPrizes] = React.useState<Prize[]>(null);
  const [qr, setQr] = React.useState<{
    label: string;
    value: Qr;
  }>(null);
  const [status, setStatus] = React.useState<{
    label: RAFFLE_STATUS;
    value: RAFFLE_STATUS;
  }>({ label: RAFFLE_STATUS.UNCOMMENCED, value: RAFFLE_STATUS.UNCOMMENCED });
  const [type, setType] = React.useState<{
    label: RAFFLE_TYPE;
    value: RAFFLE_TYPE;
  }>({ label: RAFFLE_TYPE.REGULAR, value: RAFFLE_TYPE.REGULAR });
  const [startDate, setStartDate] = React.useState<Date>(null);
  const [endDate, setEndDate] = React.useState<Date>(null);
  const [runAt, setRunAt] = React.useState<Date>(null);
  const [timeZone, setTimeZone] =
    React.useState<{ label: string; value: string }>(null);
  const [timeZones, setTimeZones] = React.useState<TimeZone[]>([]);
  const [tz, setTz] = React.useState<string>("");
  const [claimSeconds, setClaimSeconds] = React.useState<number>(null);
  const [winnerNumber, setWinnerNumber] = React.useState<number>(null);
  const [latitude, setLatitude] = React.useState<number>(null);
  const [longitude, setLongitude] = React.useState<number>(null);
  const [active, setActive] = React.useState<boolean>(false);
  const [valid, setValid] = React.useState<boolean>(false);
  const [isGrandPrize, setIsGrandPrize] = React.useState<boolean>(false);
  const [collectionLatitude, setCollectionLatitude] = React.useState(0);
  const [collectionLongitude, setCollectionLongitude] = React.useState(0);
  const [collectionDate, setCollectionDate] = React.useState<Date>(null);
  const [collectionAdditionalAddress, setCollectionAdditionalAddress] =
    React.useState(null);
  const [title, setTitle] = React.useState<string>(null);
  const [description, setDescription] = React.useState<string>(null);

  const [winners, setWinners] = React.useState<RaffleWinner[]>([]);

  const fetchRaffle = React.useCallback(async () => {
    if (uuid) {
      const { doc } = await raffleService({ uuid });
      console.warn("doc", doc);
      const raffle = doc as Raffle;

      setTitle(raffle.title);
      setDescription(raffle.description);
      setOrganization({
        label: raffle?.organization?.name,
        value: raffle?.organization,
      });
      setPrizes(raffle.prizes as Prize[]);
      setQr({ label: raffle.qr.code, value: raffle.qr });
      setStatus({ label: raffle.status, value: raffle.status });
      setType({ label: raffle.type, value: raffle.type });

      setRunAt(new Date(raffle.settings?.runAt ?? new Date()));
      setClaimSeconds(raffle.settings.claimSeconds);
      setWinnerNumber(raffle.settings.winners);
      setTz(raffle.settings?.timeZone);

      setStartDate(new Date(raffle.startDate));
      setEndDate(new Date(raffle.endDate));
      setLatitude(raffle.location.coordinates[1]);
      setLongitude(raffle.location.coordinates[0]);
      setActive(raffle.active);
      setEvent(
        raffle.event ? { label: raffle.event.title, value: raffle.event } : null
      );
      setTeam({ label: raffle.team?.name, value: raffle.team });
      setIsGrandPrize(raffle.isGrandPrize ?? false);

      setCollectionDate(
        new Date(raffle?.settings?.collection?.date ?? new Date())
      );
      if (!raffle?.settings?.collection?.location.coordinates[1]) {
        navigator?.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            setCollectionLatitude(lat);
            setCollectionLongitude(lng);
          }
        );
      } else {
        setCollectionLatitude(
          raffle?.settings?.collection?.location.coordinates[1]
        );
        setCollectionLongitude(
          raffle?.settings?.collection?.location.coordinates[0]
        );
      }

      setWinners(raffle.winners);

      setCollectionAdditionalAddress(
        raffle?.settings?.collection?.additionalAddress
      );
    }
  }, [uuid]);

  const fetchTimeZones = React.useCallback(async () => {
    const res = await timeZoneService();
    setTimeZones(res);
    const timz = res.find((t) => t.value === tz);
    setTimeZone({ label: timz?.name, value: timz?.value });
  }, [tz]);

  React.useEffect(() => {
    fetchRaffle();
    if (timeZones.length === 0 || tz) {
      fetchTimeZones();
    }
  }, [fetchRaffle, fetchTimeZones]);

  React.useEffect(() => {
    const isValid: boolean =
      ((type?.value === RAFFLE_TYPE.REGULAR && !event) ||
        (type?.value === RAFFLE_TYPE.MULTIPLE && !!event)) &&
      !!team &&
      !!organization &&
      !!prizes &&
      !!qr &&
      !!status &&
      !!startDate &&
      !!endDate &&
      !!latitude &&
      !!longitude &&
      !!collectionLatitude &&
      !!collectionLongitude &&
      !!collectionDate;

    setValid(isValid);
  }, [
    event,
    team,
    organization,
    prizes,
    qr,
    status,
    type,
    startDate,
    endDate,
    latitude,
    longitude,
  ]);

  const update = async () => {
    try {
      const input = {
        uuid,
        title,
        description,
      };

      const res = await updateRaffleService({ input });
      console.warn("res", res);
      if (!res || !res?.message) throw new Error("Error updating raffle");
      await fetchRaffle();
      toast.success("Raffle updated successfully");
    } catch (error) {
      console.warn("error", error);
      toast.success(error.message);
    }
  };

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
    }
  };

  const loadEvents = React.useCallback(
    debounce(
      (query: string) =>
        eventsService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((event: Event) => ({
            label: event.title,
            value: event,
          }));
        }),
      500
    ),
    []
  );

  const loadOrganizations = React.useCallback(
    debounce(
      (query: string) =>
        organizationsService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((organization: Organization) => ({
            label: organization.name,
            value: organization,
          }));
        }),
      500
    ),
    []
  );

  const loadPrizes = React.useCallback(
    debounce(
      (query: string) =>
        prizesService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((prize: Prize) => ({
            label: prize.title,
            value: prize,
          }));
        }),
      500
    ),
    []
  );

  const loadQrs = React.useCallback(
    debounce(
      (query: string) =>
        qrsService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((qr: Qr) => ({
            label: qr.code,
            value: qr,
          }));
        }),
      500
    ),
    []
  );

  const loadTeams = React.useCallback(
    debounce(
      (query: string) =>
        teamsService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((team: Team) => ({
            label: team.name,
            value: team,
          }));
        }),
      500
    ),
    []
  );

  const confirmDeletion = () => {
    setConfirmDialog({
      title: "Cancel Raffle",
      message: "Are you sure you want to cancel this raffle?",
      onSubmit: () => {
        deleteRaffle();
        setShowConfirmDialog(false);
      },
    });

    setShowConfirmDialog(true);
  };

  const deleteRaffle = async () => {
    try {
      let response = await deleteRaffleService(uuid);
      console.log(response);
      if (response?.message) {
        toast.success(response.message);
        navigation(ROUTE_DEFINITIONS.RAFFLE.path);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const canContinue =
    valid &&
    !prizeServiceLoading &&
    !eventsServiceLoading &&
    !organizationsServiceLoading &&
    !qrsServiceLoading &&
    !teamsServiceLoading &&
    !raffleServiceLoading &&
    !timeZoneServiceLoading;

  return (
    <div className="flex flex-col bg-white rounded h-ful">
      {showConfirmDialog && (
        <MessageModal
          confirm={true}
          title={confirmDialog?.title}
          message={confirmDialog?.message}
          onDismiss={() => setShowConfirmDialog(false)}
          onSubmit={() => confirmDialog?.onSubmit()}
        />
      )}
      <div className="w-full sm:w-100 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">
          {uuid ? "Raffle details" : "New raffle"}
        </h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <Tabs
              value={activeTab}
              onChange={(e, value) => {
                setActiveTab(value);
              }}
            >
              <Tab label="Details" value={1} selected={activeTab == 1}></Tab>
              <Tab label="Winners" value={2} selected={activeTab == 2}></Tab>
            </Tabs>
            <form
              className={`flex flex-col space-y-6 ${
                activeTab !== 1 ? "hidden" : ""
              } mt-2`}
              onSubmit={async (e) => {
                e.preventDefault();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Title</p>
                <div className="flex flex-col">
                  <div>
                    <input
                      required
                      id="title"
                      name="title"
                      value={title ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Title"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Description</p>
                <div className="flex flex-col">
                  <div>
                    <input
                      required
                      id="description"
                      name="description"
                      value={description ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Description"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Raffle Status
                </p>
                <div className="flex flex-col">
                  <div>
                    <Select
                      id="status"
                      name="status"
                      placeholder="Status status (optional)"
                      classNamePrefix="select"
                      value={status}
                      isDisabled={true}
                      options={React.useMemo(
                        () =>
                          Object.values(RAFFLE_STATUS).map((t) => ({
                            label: t,
                            value: t,
                          })),
                        [RAFFLE_STATUS]
                      )}
                      onChange={setStatus}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Raffle Type</p>
                <div className="flex flex-col">
                  <div>
                    <Select
                      id="type"
                      name="type"
                      placeholder="Raffle type"
                      classNamePrefix="select"
                      value={type}
                      isDisabled={true}
                      options={React.useMemo(
                        () =>
                          Object.values(RAFFLE_TYPE).map((t) => ({
                            label: t,
                            value: t,
                          })),
                        [RAFFLE_STATUS]
                      )}
                      onChange={setType}
                    />
                  </div>
                </div>
              </div>

              {type.value === RAFFLE_TYPE.MULTIPLE && (
                <div className="flex flex-col">
                  <p className="text-xs text-gray-400 capitalize">
                    Raffle event
                  </p>
                  <div className="flex flex-col">
                    <div>
                      <label htmlFor="event" className="sr-only">
                        Event
                      </label>
                      <AsyncSelect
                        id="event"
                        name="event"
                        placeholder="Event"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={event}
                        loadOptions={loadEvents}
                        onChange={setEvent}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Raffle organization
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="organization" className="sr-only">
                      Organization
                    </label>
                    <AsyncSelect
                      id="organization"
                      name="organization"
                      placeholder="Organization"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={organization}
                      defaultOptions={[]}
                      loadOptions={loadOrganizations}
                      onChange={setOrganization}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Raffle start date
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="organization" className="sr-only">
                      Start date
                    </label>
                    <div className="flex border rounded">
                      <DatePicker
                        className="w-full p-2 focus:outline-none rounded"
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="Raffle start date"
                        selected={startDate}
                        onChange={(date) => setStartDate(date as Date)}
                        readOnly
                      />
                      <CalendarIcon className="w-6 pr-2" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Raffle end date
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="organization" className="sr-only">
                      End date
                    </label>
                    <div className="flex border rounded">
                      <DatePicker
                        className="w-full p-2 focus:outline-none rounded"
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="Raffle end date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date as Date)}
                        readOnly
                      />
                      <CalendarIcon className="w-6 pr-2" />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%", height: "200px" }}>
                <p className="text-xs text-gray-400 capitalize">Location</p>
                {latitude && longitude ? (
                  <Map
                    showMarker={true}
                    defaultMarkerIcon={false}
                    markerPos={{ lat: latitude, lng: longitude }}
                    onChange={(e: Location) => {
                      setLatitude(e.lat);
                      setLongitude(e.lng);
                    }}
                    readonly={true}
                  />
                ) : null}
              </div>
              <div className="flex flex-col">
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <input
                      required
                      id="latitude"
                      name="latitude"
                      type="number"
                      value={latitude ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Latitude"
                      onChange={onInputChange}
                      disabled
                    />
                    <input
                      required
                      id="longitude"
                      name="longitude"
                      type="number"
                      value={longitude ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Longitude"
                      onChange={onInputChange}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/* Collection info */}
              <div style={{ width: "100%", height: "200px" }}>
                <p className="text-xs text-gray-400 capitalize">
                  Location for collection
                </p>
                <Map
                  showMarker={true}
                  defaultMarkerIcon={true}
                  markerPos={{
                    lat: collectionLatitude || 0,
                    lng: collectionLongitude || 0,
                  }}
                  onChange={(e: Location) => {
                    setCollectionLatitude(e.lat);
                    setCollectionLongitude(e.lng);
                  }}
                  readonly={true}
                />
              </div>
              <div className="flex flex-col">
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <input
                      required
                      id="collectionLatitude"
                      name="collectionLatitude"
                      type="number"
                      value={collectionLatitude ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Latitude"
                      disabled
                    />
                    <input
                      required
                      id="collectionLongitude"
                      name="collectionLongitude"
                      type="number"
                      value={collectionLongitude ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Longitude"
                      disabled
                    />
                  </div>
                  <div>
                    <input
                      required
                      id="collectionAdditionalAddress"
                      name="collectionAdditionalAddress"
                      value={collectionAdditionalAddress ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Additional address"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Collection date
                </p>
                <div className="flex flex-col">
                  <div>
                    <div className="flex border rounded">
                      <DatePicker
                        className="w-full p-2 focus:outline-none rounded"
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="Raffle end date"
                        selected={collectionDate}
                        onChange={(date) => setCollectionDate(date as Date)}
                        disabled
                      />
                      <CalendarIcon className="w-6 pr-2" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Prizes</p>
                <div className="flex flex-col">
                  <PrizesList prizes={prizes} winners={winnerNumber} />
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Qr</p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="qr" className="sr-only">
                      Qr
                    </label>
                    <AsyncSelect
                      id="qr"
                      name="qr"
                      placeholder="Qr"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={qr}
                      loadOptions={loadQrs}
                      onChange={setQr}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-sm text-gray-400 capitalize mb-2">
                  Raffle settings
                </p>
                <div className="flex flex-col space-y-2">
                  <div>
                    <p className="text-xs text-gray-400 capitalize">Timezone</p>
                    <Select
                      required
                      id="timeZone"
                      name="timeZone"
                      placeholder="Timezones"
                      value={timeZone}
                      options={React.useMemo(
                        () =>
                          timeZones?.map((tZ) => ({
                            label: tZ.name,
                            value: tZ.value,
                          })),
                        [timeZones]
                      )}
                      onChange={setTimeZone}
                      isDisabled={true}
                    />
                  </div>
                  <div>
                    <p className="text-xs text-gray-400 capitalize">Run at</p>
                    <div className="flex border rounded">
                      <DatePicker
                        className="w-full p-2 focus:outline-none rounded"
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        placeholderText="Raffle run date"
                        selected={runAt}
                        onChange={(date) => setRunAt(date as Date)}
                        disabled
                      />
                      <CalendarIcon className="w-6 pr-2" />
                    </div>
                  </div>
                  <div>
                    <p className="text-xs text-gray-400 capitalize">
                      Seconds to claim
                    </p>
                    <input
                      required
                      id="claimSeconds"
                      name="claimSeconds"
                      type="number"
                      value={claimSeconds ?? ""}
                      className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Seconds to claim"
                      onChange={onInputChange}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mb-28 flex gap-10">
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    checked={active}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onChange={() => setActive(!active)}
                    disabled
                  />
                  <span className="text-gray-500 font-medium">Is active</span>
                </label>
                <label className="flex items-center space-x-3 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    checked={isGrandPrize}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onChange={() => setIsGrandPrize(!isGrandPrize)}
                    disabled
                  />
                  <span className="text-gray-500 font-medium">
                    Is Grand Prize ⭐️ {isGrandPrize}
                  </span>
                </label>
              </div>

              <div className="w-100 flex rounded shadow-sm ml-auto gap-2">
                {status?.value !== RAFFLE_STATUS.CANCELLED &&
                status?.value !== RAFFLE_STATUS.FINALIZED ? (
                  <button
                    className="w-100 py-2 px-4 text-base text-white bg-red-500 rounded-l-lg rounded-r-lg hover:bg-gray-600"
                    onClick={() => confirmDeletion()}
                  >
                    Cancel
                  </button>
                ) : null}
                <button
                  className="w-100 py-2 px-4 text-base text-white bg-blue-500 rounded-l-lg rounded-r-lg hover:bg-gray-600"
                  onClick={() => update()}
                >
                  Update
                </button>
              </div>
            </form>
            <div className={`${activeTab !== 2 && "hidden"} w-100`}>
              {(winners?.length && <Winners Winners={winners} />) || (
                <p>No winners yet</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageRaffle;
