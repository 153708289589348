import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { Pagination, PaginationParams } from 'types/Pagination';
import Response from 'types/Response';
import { CreateEvent, Event, GetEvent, UpdateEvent } from 'types/Event';

const GET_EVENTS = gql`
  query events($input: BasePaginationInput!) {
    events(input: $input) {
      docs {
        ... on Event {
          uuid
          title
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_EVENT = gql`
  query event($uuid: String!) {
    event(uuid: $uuid) {
      message
      doc {
        ... on Event {
          uuid
          title
          qrs {
            uuid
            code
          }
        }
      }
    }
  }
`;

const CREATE_EVENT = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      doc {
        ... on Event {
          uuid
          title
        }
      }
      message
    }
  }
`;

const UPDATE_EVENT = gql`
  mutation updateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      doc {
        ... on Event {
          uuid
          title
        }
      }
      message
    }
  }
`;

type EventServices = [services: Services, loading?: boolean];

interface Services {
  eventsService: (input: PaginationParams) => Promise<Pagination<Event>>;
  eventService: (input: GetEvent) => Promise<Response>;
  createEventService: (input: CreateEvent) => Promise<Response>;
  updateEventService: (input: UpdateEvent) => Promise<Response>;
}

const useEventServices = (): EventServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const eventsService = (
    input: PaginationParams,
  ): Promise<Pagination<Event>> => {
    setLoading(true);
    return client
      .query({
        query: GET_EVENTS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const eventsData = data.events;
        setLoading(false);

        return {
          ...eventsData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const eventService = ({ uuid }: GetEvent): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_EVENT,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const eventData = data.event;
        setLoading(false);

        return {
          ...eventData,
        };
      });
  };

  const createEventService = (input: CreateEvent): Promise<Response> => {
    return client
      .mutate({
        mutation: CREATE_EVENT,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const createEventData = data.createEvent;

        return {
          ...createEventData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const updateEventService = (input: UpdateEvent): Promise<Response> => {
    return client
      .mutate({
        mutation: UPDATE_EVENT,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updateEventData = data.updateEvent;

        return {
          ...updateEventData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  return [
    {
      eventService,
      eventsService,
      createEventService,
      updateEventService,
    },
    loading,
  ];
};

export default useEventServices;
