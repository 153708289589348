import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { Stats } from 'types/Stat';

const GET_STATS = gql`
  query stats {
    stats {
      doc
    }
  }
`;


type StatServices = [services: Services, loading?: boolean];

interface Services {
  statsService: () => Promise<Stats>;
}

const useStatsService = (): StatServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const statsService = (): Promise<Stats> => {
    setLoading(true);
    return client
      .query({
        query: GET_STATS,
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const stats = data.stats;
        setLoading(false);

        return stats
      })
      .catch(() => ({ doc: [] }));
  };

  return [
    {
      statsService,
    },
    loading,
  ];
};

export default useStatsService;
