import * as React from "react";
import { toast } from "react-toastify";
import { includesRoles } from "libs";
import { getSession, removeSession, storeSession } from "libs/ApolloClient";
import useAuthServices from "services/AuthService";
import { Auth, LoginService } from "types/Auth";
import { ROLES } from "types/User";

interface AuthContextType {
  session: Auth & { loading: boolean };
  login?: (input: LoginService) => void;
  logout?: () => void;
}

const AuthContext = React.createContext<AuthContextType>(null);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { tokenLoginService, loginService, logoutService } = useAuthServices();
  const [session, setSession] = React.useState<Auth & { loading: boolean }>({
    ...getSession(),
    loading: false,
  });

  const checkLogin = (res: any) => {
    if (!res.user) {
      throw Error(res.message as any);
    }

    const { user, token } = res;
    const isAdminLoggeable = includesRoles({
      userRoles: user.roles,
      roles: [ROLES.ADMIN, ROLES.MASTER, ROLES.ORG],
    });

    if (!isAdminLoggeable) {
      throw Error("Access denied!");
    }

    return {
      token,
      user,
    };
  };

  React.useEffect(() => {
    if (session.loginFetched && !session.loggedIn && !session.loading) {
      setSession({ loading: true });
      tokenLoginService()
        .then((res: Auth) => {
          setSession({
            ...checkLogin(res),
            loggedIn: true,
            loginFetched: true,
            loading: false,
          });
          storeSession({ loginFetched: true, loggedIn: true, ...res });
        })
        .catch(({ message }) => {
          console.log("error message", message);
          toast.error(message);
          setSession({ loading: false });
          removeSession();
        });
    }
  }, [session.loggedIn]);

  const login = (input: LoginService) => {
    setSession({ loading: true });
    loginService(input)
      .then((res: Auth) => {
        console.warn("login ,.asjknbjkfs", res);
        setSession({
          ...checkLogin(res),
          loggedIn: true,
          loginFetched: true,
          loading: false,
        });
        storeSession({ loginFetched: true, token: res?.token });
      })
      .catch(({ message }) => {
        toast.error(message);
        setSession({ loading: false });
        removeSession();
      });
  };

  const logout = async () => {
    await logoutService();
    setSession({
      loggedIn: false,
      loginFetched: false,
      loading: false,
    });
    removeSession();
  };

  const value = { session, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export default AuthProvider;
