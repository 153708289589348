import {
  CheckCircleIcon,
  CheckIcon,
  ReceiptTaxIcon,
} from "@heroicons/react/outline";
import {
  QrcodeIcon,
  TemplateIcon,
  TicketIcon,
  UsersIcon,
  BriefcaseIcon,
  GiftIcon,
  CalendarIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { CardContent, Typography } from "@material-ui/core";
import React from "react";
import { Stat } from "types/Stat";
import { useNavigate } from "react-router-dom";

const collectionMeta: any = {
  Qrs: {
    icon: <QrcodeIcon />,
    path: "/qrs",
  },
  Templates: {
    icon: <TemplateIcon />,
    path: "/templates",
  },
  Raffles: {
    icon: <TicketIcon />,
    path: "/raffles",
  },
  Users: {
    icon: <UsersIcon />,
    path: "/users",
  },
  Organizations: {
    icon: <BriefcaseIcon />,
    path: "/organizations",
  },
  Prizes: {
    icon: <GiftIcon />,
    path: "/prizes",
  },
  Events: {
    icon: <CalendarIcon />,
    path: "/events",
  },
  Admins: {
    icon: <UserGroupIcon />,
  },
  Guests: {
    icon: <UserGroupIcon />,
  },
  Orgs: {
    icon: <BriefcaseIcon />,
  },
  Masters: {
    icon: <UserGroupIcon />,
  },
  Verified: {
    icon: <CheckCircleIcon />,
  },
};

const StatComponent: React.FC<Stat> = ({
  collection,
  value,
  filter,
  isSelectable,
  isSelected,
  onSelect,
}) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <CardContent
        className={`p-6 rounded-lg shadow-lg cursor-pointer ${
          isSelected
            ? "bg-gray-600 text-white hover:bg-gray-500"
            : "bg-white hover:bg-gray-100"
        }`}
        style={{ width: 200 }}
        onClick={() => {
          if (!isSelectable) return navigate(collectionMeta[collection].path);
          onSelect(filter);
        }}
      >
        <Typography>{collection}</Typography>
        <Typography
          variant="h5"
          component="div"
          className="flex justify-between items-center"
          style={{ marginTop: "10px" }}
        >
          {value}
          <span className="w-10">{collectionMeta[collection]?.icon}</span>
        </Typography>
      </CardContent>
    </React.Fragment>
  );
};

export default StatComponent;
