import { gql, useApolloClient } from '@apollo/client';
import { Auth, LoginService } from 'types/Auth';

const useAuthServices = () => {
  const client = useApolloClient();

  const loginService = (input: LoginService): Promise<Auth> => {
    return client
      .query({
        query: gql`
          query login($input: LoginInput!) {
            login(input: $input) {
              user {
                username
                roles
                permissions
                email
                organization {
                  uuid
                  name
                  details
                  settings {
                    allowGrandPrizeRaffleCreation
                    authGrandPrizeLinkAuto
                  }
                }
                profile {
                  firstname
                  lastname
                }
              }
              token
            }
          }
        `,
        variables: {
          input: {
            ...input,
          },
        },
      })
      .then(({ data }) => {
        const { token, user } = data.login;

        return {
          user,
          token,
        };
      });
  };

  const logoutService = (): Promise<any> => {
    return client.query({
      query: gql`
        query logout {
          logout
        }
      `,
    });
  };

  const tokenLoginService = (): Promise<Auth> => {
    return client
      .query({
        query: gql`
          query tokenLogin {
            tokenLogin {
              user {
                username
                roles
                permissions
                email
                organization {
                  uuid
                  name
                  details
                  settings {
                    allowGrandPrizeRaffleCreation
                    authGrandPrizeLinkAuto
                  }
                }
                profile {
                  firstname
                  lastname
                }
              }
              token
            }
          }
        `,
      })
      .then(({ data }) => {
        const tokenLoginData = data.tokenLogin;

        return {
          ...tokenLoginData,
        };
      })
      .catch((err) => {
        return err;
      });
  };

  const getRolesPermissionsService = () => {
    return client
      .query({
        query: gql`
          query rolesPermissions {
            rolesPermissions {
              roles
              permissions
            }
          }
        `,
      })
      .then(({ data }) => {
        const rolesPermissionsData = data.rolesPermissions;

        return {
          ...rolesPermissionsData,
        };
      });
  };

  return {
    loginService,
    logoutService,
    tokenLoginService,
    getRolesPermissionsService,
  };
};

export default useAuthServices;
