import React from "react";
import { ModalContext, ModalProps } from "./ModalProvider";

interface ModalHeaderProps {
  children: React.ReactNode;
  showCloseButon?: boolean;
  onClose?: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps & ModalProps> = ({
  children,
  showCloseButon,
  onClose,
}) => {
  const { onDismiss } = React.useContext(ModalContext);

  return (
    <div
      className="flex font-medium text-2xl text-gray-600 mb-8 pb-2 border-b-2"
      id="modal-title"
    >
      <div className="w-5/6">{children}</div>
      {showCloseButon && (
        <div
          className="w-1/6 flex items-center text-xs text-right cursor-pointer"
          onClick={() => {
            if (onDismiss) onDismiss();
            onClose();
          }}
        >
          <span className="w-full text-lg">X</span>
        </div>
      )}
    </div>
  );
};

export default ModalHeader;
