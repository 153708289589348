import { ROLES } from 'types/User';

export const isOnlyNumber = (value: string) => {
  return /^[0-9]*$/.test(value);
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validPhoneNumberFormat = (number: string) => {
  const re = /^[0-9\+]{1,}[0-9\-]{3,15}$/;
  return re.test(String(number).toLowerCase()) && number.length >= 10;
};

export const includesRoles = ({
  userRoles,
  roles,
}: {
  userRoles: ROLES[];
  roles: ROLES[];
}) => {
  return (
    userRoles?.length > 0 && userRoles?.some((role) => roles.includes(role))
  );
};

export const deepCopy = (value: any) => JSON.parse(JSON.stringify(value));

export const removeKeysFromObject = (object: any, keysToRemove: string[]) => {
  const tempObject = Object.keys(object).filter(
    (prop) => !keysToRemove.includes(prop),
  );
  const newObject = {} as any;
  tempObject.forEach((key) => {
    newObject[key] = object[key];
  });

  return newObject;
};

export const toBase64 = async (file: File) =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
