import { v4 } from "uuid";
import { Spinner } from "components/UI";
import { toBase64 } from "libs";
import * as React from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import useTemplateServices from "services/TemplateService";
import { Template } from "types/Template";
import DynamicElement, {
  DynamicElement as DynamicElementType,
} from "./DynamicElement";
import { Qr } from "types/Qr";
import html2canvas from "html2canvas";
import { convertImageUrlToBase64 } from "utils/general";

let availableElements: DynamicElementType[] = [];

const TemplateEditor: React.FC = () => {
  const { uuid } = useParams();
  const [currentElements, setCurrentElements] = React.useState<
    DynamicElementType[]
  >([]);
  const [{ templateService, updateTemplateService }, templateServiceLoading] =
    useTemplateServices();
  const [template, setTemplate] = React.useState<Template>(null);
  const [file, setFile] = React.useState<File>(null);
  const ref = React.useRef();
  const refCanvas = React.useRef();

  React.useEffect(() => {
    if (template && template.qrs) {
      availableElements = [
        {
          id: v4(),
          type: "text",
          value: null,
          width: 100,
          height: 50,
          top: 10,
          left: 10,
          rotateAngle: 0,
        },
        {
          id: v4(),
          type: "textarea",
          value: null,
          width: 100,
          height: 50,
          top: 10,
          left: 10,
          rotateAngle: 0,
        },
        {
          id: v4(),
          type: "image",
          value: null,
          width: 100,
          height: 50,
          top: 10,
          left: 10,
          rotateAngle: 0,
        },
        {
          id: v4(),
          type: "qr ",
          value: "",
          width: 258,
          height: 258,
          top: 10,
          left: 10,
          rotateAngle: 0,
        },
        {
          id: v4(),
          type: "prize-list",
          value: null,
          width: 100,
          height: 50,
          top: 10,
          left: 10,
          rotateAngle: 0,
        },
      ];
      template.qrs.forEach((qr: Qr) => {
        if (
          !availableElements.find(
            (ae: DynamicElementType) => ae.type === "qr " + qr.code
          )
        ) {
          availableElements.push({
            id: v4(),
            type: "qr " + qr.code,
            value: qr.code,
            width: 258,
            height: 258,
            top: 10,
            left: 10,
            rotateAngle: 0,
          });
        }
      });
    }
  }, [template]);

  const fetchTemplate = React.useCallback(async () => {
    if (uuid) {
      const { doc } = await templateService({ uuid });
      const t = doc as Template;
      console.log(t);
      setTemplate(t);
      setCurrentElements(t.templateElements);
    }
  }, [uuid]);

  React.useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  const generateDefaultName = (index: number): string => {
    const name = `el${index < 10 ? "0" + index : index}`;
    if (currentElements.find((el) => el.name === name)) {
      return generateDefaultName(index + 1);
    }
    return name;
  };

  const addElement = (el: DynamicElementType, index: number) => {
    el.id = `${index}${el.id}`;
    el.name = generateDefaultName(currentElements.length);

    currentElements.push(el);
    setCurrentElements([...currentElements]);
  };

  const onChange = (el: DynamicElementType) => {
    const tmp = currentElements.map((elm) => {
      if (elm.id === el.id) {
        return el;
      } else {
        return elm;
      }
    });

    setCurrentElements([...tmp]);
  };

  const onRemove = (el: DynamicElementType) => {
    const tmp = currentElements.filter((elm) => elm.id !== el.id);

    setCurrentElements([...tmp]);
  };

  const onBackgroundLoad = async (e: React.SyntheticEvent) => {
    const { files } = e.currentTarget as HTMLInputElement;
    const uri = await toBase64(files[0]);

    setTemplate({
      ...template,
      backgroundUrl: uri as string,
    });
    setFile(files[0]);
  };

  const onSave = async () => {
    console.log(currentElements);
    const res = await updateTemplateService({
      input: {
        uuid: template.uuid,
        title: template.title,
        templateElements: currentElements,
        file,
        previewUrl: await getPreview(),
      },
    });

    toast.success(res.message);
  };

  const getPreview = async (): Promise<string> => {
    let bgImage = await convertImageUrlToBase64(template.backgroundUrl);

    return new Promise((resolve, reject) => {
      html2canvas(refCanvas.current, {
        onclone: function (doc, element) {
          element.querySelectorAll(".single-resizer").forEach((el) => {
            el.setAttribute("style", "display:none");
          });

          if (bgImage) {
            const bg = doc.createElement("img");
            bg.src = bgImage;
            console.log(bg);

            element.appendChild(bg);

            bg.setAttribute("style", "width:1020px; height:100%;");
            element.appendChild(bg);
          }
        },
        scale: 0.2,
      })
        .then(function (canvas) {
          const dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  if (!template || templateServiceLoading) {
    return <Spinner className="w-12 h-12" text="Loading..." />;
  }

  return (
    <div className="flex w-full h-full rounded space-x-2">
      <div
        ref={ref}
        className="font-sans text-base myDivToPrint flex justify-center relative w-5/6 bg-gray-200 rounded border border-gray-300 overflow-x-auto"
      >
        <img
          className="absolute"
          style={{ height: 1020, minWidth: 790 }}
          src={template.backgroundUrl}
        />

        <div
          id="test"
          className="absolute"
          ref={refCanvas}
          style={{ height: 1020, minWidth: 790 }}
        >
          {currentElements.map((props, index) => {
            return (
              <DynamicElement
                key={`${props.type}-${index}-currentElement`}
                name={props.name}
                onChange={onChange}
                onRemove={onRemove}
                currentElements={currentElements}
                {...props}
              />
            );
          })}
        </div>
      </div>

      <div className="flex flex-col w-1/6 bg-gray-200 p-2 text-center rounded border border-gray-300 space-y-2">
        <p className="pb-4 text-xl font-bold">Available elements</p>
        <ul className="flex-1 space-y-2">
          {availableElements.map((el, index) => {
            return (
              <li
                className="rounded text-base py-2 cursor-pointer uppercase bg-gray-300 border border-gray-400 shadow-sm text-gray-500 hover:text-gray-900 hover:border-gray-600"
                key={`${el.type}-${index}-element`}
                onClick={() => addElement(el, index)}
              >
                {el.type}
              </li>
            );
          })}
        </ul>

        <button className="p-2 rounded bg-gray-300 border border-gray-400 text-gray-500 hover:text-gray-900 hover:border-gray-600">
          <label className="relative">
            Load Background
            <input
              type="file"
              className="opacity-0 absolute"
              accept="image/png"
              onChange={onBackgroundLoad}
            />
          </label>
        </button>
        <ReactToPrint
          trigger={() => (
            <button className="p-2 rounded bg-gray-300 border border-gray-400 text-gray-500 hover:text-gray-900 hover:border-gray-600">
              Print
            </button>
          )}
          content={() => ref.current}
          pageStyle={`
          @media print {
            .myDivToPrint {
                height: 100%!important;
                width: 100%!important;
            }
            img {
              width: 1020px!important;
            }
            .myDivToPrint {
              height: 100%!important;
              width: 100%!important;
            }  
            .single-resizer {
              display: none;
            }
          }
          `}
        />
        <button
          className="p-2 rounded bg-gray-300 border border-gray-400 text-gray-500 hover:text-gray-900 hover:border-gray-600"
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default TemplateEditor;
