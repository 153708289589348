import * as React from "react";
import { LOGO_WHITE } from "utils/constants";
import { LOGO_ICON } from "utils/constants";

import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import {
  HomeIcon,
  UserGroupIcon,
  LogoutIcon,
  AdjustmentsIcon,
  UsersIcon,
  OfficeBuildingIcon,
  QrcodeIcon,
  CalendarIcon,
  GiftIcon,
  TemplateIcon,
  TicketIcon,
  CogIcon,
} from "@heroicons/react/outline";

import { includesRoles } from "libs";
import { ROLES } from "types/User";
import { useAuth } from "AuthProvider";
import { appRoutes } from "routing";

export interface SideBarItem {
  key: string;
  label: string;
  path?: string;
  icon?: string;
  hidden?: boolean;
  roles?: ROLES[];
  childrens?: SideBarItem[];
}

interface SideBarProps {
  items?: SideBarItem[];
  expandedSideBar?: boolean;
}

// TODO: fix type.
const diccionary: any = {
  home: <HomeIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  adjusments: <AdjustmentsIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  userGroup: <UserGroupIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  users: <UsersIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  organization: <OfficeBuildingIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  qr: <QrcodeIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  event: <CalendarIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  prize: <GiftIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  raffle: <TicketIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  template: <TemplateIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
  settings: <CogIcon className="w-6 h-6 mx-auto sm:-mx-0" />,
};

export const SideBar: React.FC<SideBarProps> = ({ items, expandedSideBar }) => {
  const { session, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<string>(location.pathname);
  const [childSelected, setChildSelected] = React.useState<string>(
    location.pathname
  );
  const matches = matchRoutes(appRoutes, location);
  const { route } = Object.values(matches).pop();

  const parentSideBarItem = React.useMemo(
    () =>
      items.find((item) =>
        item?.childrens?.find(
          (chil) =>
            chil.path.includes(selected) || chil.path.includes(route.path)
        )
      ),
    [selected, route]
  );

  const getParentSideBarItem = React.useCallback(() => {
    if (parentSideBarItem) {
      setSelected(parentSideBarItem.key);
    }
  }, [selected]);

  const checkPermissions = React.useCallback(
    (item: SideBarItem) =>
      React.useMemo(
        () =>
          includesRoles({
            userRoles: session.user.roles,
            roles: item.roles,
          }),
        [(session.user.roles, item)]
      ),
    []
  );

  const parentItem = React.useCallback(
    (item: SideBarItem) =>
      selected.indexOf(item.path) >= 0 || selected.indexOf(item.key) >= 0,
    [selected]
  );

  React.useEffect(() => {
    getParentSideBarItem();
  }, [getParentSideBarItem]);

  const onItemSelect = (item: SideBarItem) => {
    const hasChildren = !!item.childrens;

    if (!hasChildren) {
      navigate(item.path);
    }

    setSelected(hasChildren ? item.key : item.path);

    if (!item.childrens) {
      setChildSelected("");
    }
  };

  const onChildItemSelect = (item: SideBarItem) => {
    navigate(item.path);
    setChildSelected(item.path);
  };

  const onLogout = async () => logout();

  return (
    <div
      className={`
    transform overflow-auto ease-in-out transition-all duration-300 z-10
    ${
      expandedSideBar ? "w-1/6" : "w-0 translate-x-0"
    } flex flex-col bg-gray-200 border-r border-gray-300
    `}
    >
      <div className="h-16 text-white flex flex-col justify-center items-center bg-gray-900">
        <img
          className="w-32 mr"
          src={expandedSideBar ? LOGO_WHITE : LOGO_ICON}
          alt="logo"
        />
      </div>
      <div className="flex flex-col flex-1 bg-gray-800 overflow-y-auto">
        <ul className="flex-1 text-gray-500 mt-4 text-sm">
          {items.map((item) => {
            return (
              checkPermissions(item) && (
                <li
                  key={item.label}
                  className={`flex flex-col py-1 px-1 sm:py-4 sm:px-4 my-2 mx-2 cursor-pointer rounded hover:text-gray-200 ${
                    parentItem(item) &&
                    "transition duration-300 ease-in-out bg-gray-900 text-gray-200"
                  }`}
                  onClick={() => onItemSelect(item)}
                >
                  <div className="flex flex-row">
                    {diccionary[item.icon]}
                    <span className="ml-2 my-auto hidden sm:block">
                      {item.label}
                    </span>
                  </div>
                  {item.childrens && parentItem(item) && (
                    <ul className="text-gray-500">
                      {item.childrens.map((children) => {
                        return (
                          includesRoles({
                            userRoles: session.user.roles,
                            roles: children.roles,
                          }) &&
                          !children.hidden && (
                            <li
                              key={children.label}
                              className={`flex flex-row py-1 px-2 sm:py-2 sm:px-4 my-2 cursor-pointer rounded hover:text-gray-200 ${
                                childSelected?.indexOf(children?.path) >= 0 &&
                                "bg-gray-900 text-gray-200"
                              }`}
                              onClick={() => onChildItemSelect(children)}
                            >
                              {diccionary[children.icon]}
                              <span className="flex ml-2 my-auto hidden sm:block">
                                {children.label}
                              </span>
                            </li>
                          )
                        );
                      })}
                    </ul>
                  )}
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div
        className="flex p-4 bottom-0 justify-center text-white text-center bg-gray-900 text-gray-500 hover:text-gray-200 cursor-pointer"
        onClick={onLogout}
      >
        <LogoutIcon className="w-6 h-6 mx-auto sm:-mx-0" />
        <span className="hidden sm:block ml-2">Logout</span>
      </div>
      <div className="text-xs text-left px-4 py-1 bg-gray-900 text-gray-700">
        Version: {process.env.appVersion}
      </div>
    </div>
  );
};

export default SideBar;
