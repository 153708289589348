export const convertImageUrlToBase64 = (url: string): Promise<string> => {
  if (!url) return null;
  if (url.startsWith("data:")) return Promise.resolve(url);
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(
          reader.result
            .toString()
            .replace("application/octet-stream", "image/png")
        );
      };
      reader.readAsDataURL(xhr.response);
    };
    var proxyUrl = process.env.HOST + "/proxy-cors?url=";
    xhr.open("GET", proxyUrl + url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

export const getImg = (url: string) => {
  return url ? `${url}?${new Date().getTime()}` : null;
};
