import * as React from "react";
import lodash from "lodash";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";

import { User } from "types/User";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { toast } from "react-toastify";
import useUserServices from "services/UserService";
import useOrganizationServices from "services/OrganizationService";
import { Organization } from "types/Organization";
import Map from "../UI/Map";
import { Location } from "../UI/Map";
const ManageOrganization: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [{ usersService }, userServieLoading] = useUserServices();
  const [
    {
      organizationService,
      createOriganizationService,
      updateOriganizationService,
    },
    organizationServiceLoading,
  ] = useOrganizationServices();
  const [organization, setOrganization] = React.useState<Organization>(null);
  const [location, setLocation] = React.useState({ lat: 0, lng: 0 });
  const [user, setUser] = React.useState<{ label: string; value: User }>(null);
  const [allowGrandPrizeRaffleCreation, setAllowGrandPrizeRaffleCreation] =
    React.useState(false);
  const [authGrandPrizeLinkAuto, setAuthGrandPrizeLinkAuto] =
    React.useState(false);

  const fetchOrganization = React.useCallback(async () => {
    if (uuid && !organization) {
      const { doc } = await organizationService({ uuid });
      const t = doc as Organization;
      setOrganization(t);

      setLocation({
        lat: t.location?.coordinates[1],
        lng: t.location?.coordinates[0],
      });

      setAllowGrandPrizeRaffleCreation(
        t?.settings?.allowGrandPrizeRaffleCreation ?? false
      );

      setAuthGrandPrizeLinkAuto(t?.settings?.authGrandPrizeLinkAuto ?? false);

      setUser({ label: t.user?.username, value: t.user });
    }
  }, [uuid, organization]);

  React.useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization, uuid]);

  const onInputChange = (e: React.SyntheticEvent) => {
    console.log(organization);
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setOrganization({
      ...organization,
      ...lodash.set(organization, name, value),
    });
  };

  const onLocationChange = (location: Location) => {
    console.warn("organization", organization);
    setLocation(location);
  };

  const onSubmitHandler = async () => {
    const input = {
      name: organization.name,
      details: organization.details,
      userUUID: user?.value?.uuid,
      settings: {
        allowGrandPrizeRaffleCreation,
        authGrandPrizeLinkAuto,
      },
      location: {
        type: "Point",
        coordinates: [location.lng, location.lat],
      },
    };
    let res;

    if (!uuid) {
      res = await createOriganizationService({ input });
      navigation(ROUTE_DEFINITIONS.ORGANIZATION.path);
    } else {
      res = await updateOriganizationService({
        input: {
          ...input,
          uuid: organization.uuid,
        },
      });
    }

    console.warn("message", res);

    toast.success(res.message);
  };

  const loadUsers = React.useCallback(
    debounce(
      (query: string) =>
        usersService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((user: User) => ({
            label: user.username,
            value: user,
          }));
        }),
      500
    ),
    []
  );

  return (
    <div className="flex flex-col bg-white rounded h-full">
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">
          {uuid ? "Edit organization" : "New organization"}
        </h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Organization information
                </p>
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="name" className="sr-only">
                      Name
                    </label>
                    <input
                      required
                      id="name"
                      name="name"
                      type="text"
                      value={organization?.name ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Name"
                      onChange={onInputChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="details" className="sr-only">
                      details
                    </label>
                    <input
                      id="details"
                      name="details"
                      type="text"
                      value={organization?.details ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Details (optional)"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Location information
                </p>
                <div style={{ width: "100%", height: "200px" }}>
                  <Map
                    showMarker={true}
                    markerPos={location}
                    onChange={function (e) {
                      onLocationChange(e);
                    }}
                  />
                </div>
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="longitude" className="sr-only">
                      Longitude
                    </label>
                    <input
                      id="longitude"
                      name="location.coordinates[0]"
                      type="text"
                      value={location.lng ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Longitude (optional)"
                      disabled
                    />
                  </div>
                  <div>
                    <label htmlFor="latitude" className="sr-only">
                      Latitude
                    </label>
                    <input
                      id="latitude"
                      name="location.coordinates[1]"
                      type="number"
                      value={location?.lat ?? ""}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Latitude (optional)"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Organization user
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="user" className="sr-only">
                      User
                    </label>
                    <AsyncSelect
                      id="user"
                      name="user"
                      // isDisabled={!!organization}
                      placeholder="User"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={user}
                      loadOptions={loadUsers}
                      onChange={(user: { label: string; value: User }) =>
                        setUser(user)
                      }
                    />
                  </div>
                </div>
              </div>

              <h1 className="text-xl capitalize">Settings</h1>
              <div className="flex flex-col justify-center">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="active"
                    checked={allowGrandPrizeRaffleCreation}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onChange={() =>
                      setAllowGrandPrizeRaffleCreation(
                        !allowGrandPrizeRaffleCreation
                      )
                    }
                  />
                  <span className="text-xs text-gray-400 capitalize">
                    Allow organization to create grand prize raffles
                  </span>
                </div>

                <div className="flex items-center gap-2 mt-2">
                  <input
                    type="checkbox"
                    name="active"
                    checked={authGrandPrizeLinkAuto}
                    className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-gray-900 checked:border-gray-900 focus:outline-none"
                    onChange={() =>
                      setAuthGrandPrizeLinkAuto(!authGrandPrizeLinkAuto)
                    }
                  />
                  <span className="text-xs text-gray-400 capitalize">
                    Authorize grand prizes link automatically
                  </span>
                </div>
              </div>

              <div className="w-1/2 flex rounded shadow-sm ml-auto">
                <button
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                  onClick={() =>
                    navigation(ROUTE_DEFINITIONS.ORGANIZATION.path)
                  }
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={userServieLoading || organizationServiceLoading}
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                >
                  Accept
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageOrganization;
