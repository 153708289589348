import React from "react";

import { DEV_MODE, LOGO_ORANGE, PROD_MODE } from "utils/constants";
import { useAuth } from "AuthProvider";

interface State {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { session, login } = useAuth();
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>({
    email: "",
    password: "",
  });

  React.useEffect(() => {
    if (!session?.loggedIn && !session?.loading) {
      setRequesting(false);
    }
  }, [session, setRequesting]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const form = e.currentTarget as HTMLInputElement;
    let { value, name } = form;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmitHandler = (e: React.SyntheticEvent) => {
    setRequesting(true);
    state.email = state.email.toLowerCase();
    login(state);
    e.preventDefault();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-16 w-auto"
            src={LOGO_ORANGE}
            alt="RaffleHunter"
          />
        </div>
        <form className="mt-8 space-y-6" onSubmit={onSubmitHandler}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">
                Nombre de usuario
              </label>
              <input
                required
                id="email"
                name="email"
                type="text"
                value={state.email}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                placeholder="Email"
                onChange={onInputChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Contraseña
              </label>
              <input
                required
                id="password"
                name="password"
                type="password"
                value={state.password}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={onInputChange}
              />
            </div>
          </div>

          <div>
            <button
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                requesting
                  ? "bg-gray-400 hover:bg-gray-500"
                  : "bg-gray-600 hover:bg-gray-700"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500`}
              disabled={requesting}
            >
              {`${requesting ? "Login in" : "Login"}`}
            </button>
          </div>
          {process.env.MODE == DEV_MODE && (
            <label className="p-5 w-full flex justify-center text-red-500 bg-red-200">
              Development
            </label>
          )}
          <label className="p-2 w-full flex justify-center text-blue-500 bg-blue-200 text-sm">
            V.{process.env.appVersion}
          </label>
        </form>
      </div>
    </div>
  );
};

export default Login;
