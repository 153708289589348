import * as React from "react";
import useStatsService from "../../services/StatService";
import StatComponent from "../UI/StatComponent";

import { CircularProgress } from "@material-ui/core";
const Dashboard: React.FC = () => {
  const [{ statsService }, loading] = useStatsService();
  const [stats, setStats] = React.useState([]);
  React.useEffect(() => {
    statsService().then((res) => {
      setStats(res.doc);
    });
  }, []);

  return (
    <div className="flex flex-row w-full flex-wrap gap-5">
      {loading && <CircularProgress />}
      {stats.map((stat) => (
        <StatComponent {...stat} />
      ))}
    </div>
  );
};

export default Dashboard;
