import React from "react";
import { Prize } from "types/Prize";
import { RaffleWinner } from "types/Raffle";
import Table from "../Table";
interface Props {
  Winners?: RaffleWinner[];
}

interface WinnerFormatted {
  firstname: string;
  lastname: string;
  email: string;
  selectedAt: string;
}

const Winners: React.FC<Props> = ({ Winners = [] }) => {
  const formatData = async (query: any) => {
    let formattedData = Winners.map((winner) => {
      return {
        firstname: winner?.user?.profile?.firstname,
        lastname: winner?.user?.profile?.lastname,
        email: winner?.user?.email,
        selectedAt: new Date(winner?.selectedAt).toLocaleString(),
      };
    });
    return {
      data: formattedData,
      page: 0,
      totalCount: Winners.length,
    };
  };

  const downloadData = () => {
    // convert winners to csv format
    const csv = Winners.map((winner) => {
      return {
        firstname: winner?.user?.profile?.firstname,
        lastname: winner?.user?.profile?.lastname,
        email: winner?.user?.email,
        selectedAt: new Date(winner?.selectedAt).toLocaleString(),
      };
    }) as WinnerFormatted[];

    const csvRows = [];
    const headers = Object.keys(csv[0]);
    csvRows.push(headers.join(","));
    for (const row of csv) {
      const values = headers.map((key: string) => {
        const escaped = ("" + row[key as keyof WinnerFormatted]).replace(
          /"/g,
          '\\"'
        );
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    // download csv

    const csvString = csvRows.join("\n");
    const a = document.createElement("a");
    a.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvString);
    a.setAttribute("download", "winners.csv");
    document.body.appendChild(a);
    a.click();
  };

  return (
    <div className="flex flex-col">
      <Table
        title={`List of winners (${Winners?.length})`}
        limit={10}
        columns={[
          {
            title: "First Name",
            field: "firstname",
          },
          {
            title: "Last Name",
            field: "lastname",
          },
          { title: "Email", field: "email" },
          {
            title: "Selected At",
            field: "selectedAt",
          },
        ]}
        data={formatData}
      ></Table>
      <button
        className="w-100 py-2 px-4 text-base text-white bg-blue-500 rounded-l-lg rounded-r-lg hover:bg-gray-600 self-end mt-2"
        onClick={() => downloadData()}
      >
        Download csv
      </button>
    </div>
  );
};

export default Winners;
