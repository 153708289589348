import * as React from "react";
import { useRef, useEffect } from "react";
import Table from "components/UI/Table";
import { NavigateOptions, useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import useRaffleServices from "services/RaffleService";
import { Raffle } from "types/Raffle";
import { Tabs, Tab } from "@material-ui/core";
import { useAuth } from "AuthProvider";
import { ROLES } from "types/User";
import StatComponent from "components/UI/StatComponent";
import MessageModal from "components/UI/Message/MessageModal";

enum RAFFLES_TYPES {
  REGULAR = "REGULAR",
  GRAND_PRIZE = "grand_prize",
}

const RaffleList: React.FC = () => {
  const navigate = useNavigate();
  const [{ rafflesService, organizationRafflesService, raffleStatsService }] =
    useRaffleServices();
  const [limit, setLimit] = React.useState<number>(25);
  const tableRef = useRef(null);
  const { session } = useAuth();
  const [stats, setStats] = React.useState([]);
  const [selectedStats, setSelectedStats] = React.useState([]);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    message: "",
    title: "",
    onSubmit: () => {},
  });

  const [rafflesType, setRafflesType] = React.useState<RAFFLES_TYPES>(
    RAFFLES_TYPES.REGULAR
  );

  const onNewRaffleHandler = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_RAFFLE.path);
  };

  const onFilterRaffleRequestsHandler = async (raffle: any) => {
    navigate(ROUTE_DEFINITIONS.FILTER_GRAND_PRIZE.path, {
      state: { raffle: raffle },
    });
  };

  const onEditRaffleHandler = async (raffle: Raffle) => {
    navigate(
      replaceParam(ROUTE_DEFINITIONS.EDIT_RAFFLE.path, "uuid", raffle.uuid)
    );
  };

  const computeTableTitle = () => {
    return rafflesType == RAFFLES_TYPES.GRAND_PRIZE
      ? "Grand Prize Raffles"
      : "Raffles";
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [rafflesType, selectedStats]);

  useEffect(() => {
    raffleStatsService().then((data) => {
      setStats(
        data.doc.map((stat: any) => {
          return {
            collection: stat.title,
            value: stat.count,
            filter: stat.filter,
          };
        })
      );
    });
  }, []);

  const loadRaffles = async (query: any) => {
    const isOrg = session?.user?.roles?.includes(ROLES.ORG);
    const service = isOrg ? organizationRafflesService : rafflesService;
    let payload = {
      input: {
        page: query.page + 1 || 1,
        query: {
          $and: [
            {
              isGrandPrize: rafflesType == RAFFLES_TYPES.GRAND_PRIZE || {
                $in: [false, null],
              },
              title: {
                type: "regex",
                value: query.search,
              },
            },
            selectedStats?.length ? { $or: selectedStats } : {},
          ],
        },
      },
    };

    const res = await service(payload);

    setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

    return {
      data: res.docs,
      page: res.page - 1,
      totalCount: res.totalDocs,
    };
  };

  return (
    <>
      <div className="h-full w-full">
        <div className="flex flex-row w-full flex-wrap gap-5">
          {stats?.map((stat: any) => (
            <StatComponent
              {...stat}
              isSelectable={true}
              isSelected={selectedStats?.includes(stat.filter)}
              onSelect={(filter: any) => {
                if (selectedStats?.includes(filter)) {
                  setSelectedStats(
                    selectedStats.filter((f: any) => f != filter)
                  );
                } else {
                  setSelectedStats([...selectedStats, filter]);
                }
              }}
            />
          ))}
        </div>
        <Tabs
          onChange={(e, value) => {
            setRafflesType(value);
          }}
          value={rafflesType}
        >
          <Tab
            label="Regular Raffles"
            selected={rafflesType == RAFFLES_TYPES.REGULAR}
            value={RAFFLES_TYPES.REGULAR}
          />
          <Tab
            label="Grand Prize Raffles"
            selected={rafflesType == RAFFLES_TYPES.GRAND_PRIZE}
            value={RAFFLES_TYPES.GRAND_PRIZE}
          />
        </Tabs>
        <Table
          title={computeTableTitle()}
          limit={limit}
          ref={tableRef}
          columns={[
            { title: "Status", field: "status" },
            { title: "Type", field: "type" },
            { title: "Title", field: "title" },
            {
              title: "Active",
              render: (rowData: Raffle) => (rowData.active ? "YES" : "NO"),
            },
            {
              title: "Organization",
              render: (rowData: Raffle) => (
                <span
                  className=" text-blue-400 cursor-pointer"
                  onClick={() =>
                    navigate(
                      replaceParam(
                        ROUTE_DEFINITIONS.EDIT_ORGANIZATION.path,
                        "uuid",
                        rowData?.organization?.uuid
                      )
                    )
                  }
                >
                  {rowData?.organization?.name}
                </span>
              ),
            },
            {
              title: "Grand Prize",
              render: (rowData: Raffle) => (
                <span className=" text-blue-400 cursor-pointer">
                  {rowData?.isGrandPrize ? "⭐️" : ""}
                </span>
              ),
            },
          ]}
          data={async (query: any) => loadRaffles(query)}
          actions={[
            (_: any) => ({
              icon: "edit_note",
              tooltip: "Details",
              onClick: (_: any, rowData: Raffle) =>
                onEditRaffleHandler(rowData),
            }),
            (rowData: any) =>
              rowData?.isGrandPrize
                ? {
                    icon: "assignment",
                    tooltip: "Filter requests",
                    isFreeAction: true,
                    onClick: () => onFilterRaffleRequestsHandler(rowData),
                  }
                : null,
          ]}
        />
      </div>
    </>
  );
};

export default RaffleList;
