import * as React from "react";

import Table from "components/UI/Table";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import useEventServices from "services/EventService";
import { Event } from "types/Event";

const EventList: React.FC = () => {
  const navigate = useNavigate();
  const [{ eventsService }] = useEventServices();
  const [limit, setLimit] = React.useState<number>(25);

  const onNewEventHanlder = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_EVENT.path);
  };

  const onEditEventHandler = async (event: Event) => {
    navigate(
      replaceParam(ROUTE_DEFINITIONS.EDIT_EVENT.path, "uuid", event.uuid)
    );
  };

  return (
    <div className="h-full w-full">
      <Table
        title="Event list"
        limit={limit}
        columns={[{ title: "Title", field: "title" }]}
        data={async (query: any) => {
          const res = await eventsService({
            input: {
              page: query.page + 1,
              query: {
                title: {
                  type: "regex",
                  value: query.search,
                },
              },
            },
          });

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Edit Event",
            onClick: (_, rowData: Event) => onEditEventHandler(rowData),
          }),
          {
            icon: "add",
            tooltip: "Add Event",
            isFreeAction: true,
            onClick: onNewEventHanlder,
          },
        ]}
      />
    </div>
  );
};

export default EventList;
