import * as React from 'react';
import lodash from 'lodash';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

import { User } from 'types/User';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_DEFINITIONS } from 'types/Routes';
import { toast } from 'react-toastify';
import { Team } from 'types/Team';
import useTeamServices from 'services/TeamService';
import useUserServices from 'services/UserService';

const ManageUser: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [{ usersService }, userServiceLoading] = useUserServices();
  const [
    { teamService, createTeamService, updateTeamService },
    teamServiceLoading,
  ] = useTeamServices();
  const [team, setTeam] = React.useState<Team>(null);
  const [teamOwner, setTeamOwner] =
    React.useState<{ label: string; value: User }>(null);
  const [teamUsers, setTeamUsers] = React.useState<
    { label: string; value: User }[]
  >([]);

  const fetchTeam = React.useCallback(async () => {
    if (uuid && !team) {
      const { doc } = await teamService({ uuid });
      const t = doc as Team;

      setTeam(t);
      setTeamUsers(
        t.users?.map((user) => ({ label: user.username, value: user })),
      );
      setTeamOwner({ label: t.owner?.username, value: t.owner });
    }
  }, [uuid, team]);

  React.useEffect(() => {
    fetchTeam();
  }, [fetchTeam, uuid]);

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setTeam({
      ...team,
      ...lodash.set(team, name, value),
    });
  };

  const onSubmitHandler = async () => {
    const input = {
      name: team.name,
      code: team.code,
      ownerUUID: teamOwner?.value?.uuid,
      users:
        teamUsers?.map((u) => ({
          uuid: u.value.uuid,
        })) ?? [],
    };
    let res;

    if (!uuid) {
      res = await createTeamService({ input });
      navigation(ROUTE_DEFINITIONS.TEAM.path);
    } else {
      res = await updateTeamService({
        input: {
          ...input,
          uuid: team.uuid,
        },
      });
    }

    toast.success(res.message);
  };

  const loadUsers = React.useCallback(
    debounce(
      (query: string) =>
        usersService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((user: User) => ({
            label: user.username,
            value: user,
          }));
        }),
      500,
    ),
    [],
  );

  return (
    <div className="flex flex-col bg-white rounded h-full">
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">
          {uuid ? 'Edit team' : 'New Team'}
        </h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Team information
                </p>
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="name" className="sr-only">
                      Name
                    </label>
                    <input
                      required
                      id="name"
                      name="name"
                      type="text"
                      value={team?.name ?? ''}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Name"
                      onChange={onInputChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="code" className="sr-only">
                      Code
                    </label>
                    <input
                      id="code"
                      name="code"
                      type="text"
                      value={team?.code ?? ''}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Code (optional)"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Team owner</p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="roles" className="sr-only">
                      Owner
                    </label>
                    <AsyncSelect
                      id="owner"
                      name="owner"
                      placeholder="Owner"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={teamOwner}
                      loadOptions={loadUsers}
                      onChange={(user: { label: string; value: User }) =>
                        setTeamOwner(user)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Team users</p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="roles" className="sr-only">
                      Users
                    </label>
                    <AsyncSelect
                      id="user"
                      name="user"
                      placeholder="Users"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      value={teamUsers}
                      loadOptions={loadUsers}
                      onChange={(users: { label: string; value: User }[]) =>
                        setTeamUsers(users)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2 flex rounded shadow-sm ml-auto">
                <button
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                  onClick={() => navigation(ROUTE_DEFINITIONS.TEAM.path)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={userServiceLoading || teamServiceLoading}
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                >
                  Accept
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
