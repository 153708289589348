import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { Pagination, PaginationParams } from 'types/Pagination';
import Response from 'types/Response';
import { CreatePrize, GetPrize, Prize, UpdatePrize } from 'types/Prize';

const GET_PRIZES = gql`
  query prizes($input: BasePaginationInput!) {
    prizes(input: $input) {
      docs {
        ... on Prize {
          uuid
          title
          imageUrl
          value
          organization {
            uuid
            name
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_PRIZE = gql`
  query prize($uuid: String!) {
    prize(uuid: $uuid) {
      message
      doc {
        ... on Prize {
          uuid
          title
          imageUrl
          value
          organization {
            uuid
            name
          }
        }
      }
    }
  }
`;

const CREATE_PRIZE = gql`
  mutation createPrize($input: CreatePrizeInput!) {
    createPrize(input: $input) {
      doc {
        ... on Prize {
          uuid
          title
          imageUrl
          value
          organization {
            uuid
            name
          }
        }
      }
      message
    }
  }
`;

const UPDATE_PRIZE = gql`
  mutation updatePrize($input: UpdatePrizeInput!) {
    updatePrize(input: $input) {
      doc {
        ... on Prize {
          uuid
          title
          imageUrl
          value
          organization {
            uuid
            name
          }
        }
      }
      message
    }
  }
`;


const GET_ORGANIZATION_PRIZES = gql`
  query organizationPrizes($input: BasePaginationInput!) {
    organizationPrizes(input: $input) {
      docs {
        ... on Prize {
          uuid
          title
          imageUrl
          value
          organization {
            uuid
            name
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

type PrizeServices = [services: Services, loading?: boolean];

interface Services {
  prizesService: (input: PaginationParams) => Promise<Pagination<Prize>>;
  prizeService: (input: GetPrize) => Promise<Response>;
  createPrizeService: (input: CreatePrize) => Promise<Response>;
  updatePrizeService: (input: UpdatePrize) => Promise<Response>;
  organizationPrizesService: (input: PaginationParams) => Promise<Pagination<Prize>>;
}

const usePrizeServices = (): PrizeServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const prizesService = (
    input: PaginationParams,
  ): Promise<Pagination<Prize>> => {
    setLoading(true);
    return client
      .query({
        query: GET_PRIZES,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const prizesData = data.prizes;
        setLoading(false);

        return {
          ...prizesData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const prizeService = ({ uuid }: GetPrize): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_PRIZE,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const prizeData = data.prize;
        setLoading(false);

        return {
          ...prizeData,
        };
      });
  };

  const createPrizeService = (input: CreatePrize): Promise<Response> => {
    return client
      .mutate({
        context: {
          hasUpload: true,
        },
        mutation: CREATE_PRIZE,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const createPrizeData = data.createPrize;

        return {
          ...createPrizeData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const updatePrizeService = (input: UpdatePrize): Promise<Response> => {
    return client
      .mutate({
        context: {
          hasUpload: true,
        },
        mutation: UPDATE_PRIZE,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updatePrizeData = data.updatePrize;

        return {
          ...updatePrizeData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const organizationPrizesService = (
    input: PaginationParams,
  ): Promise<Pagination<Prize>> => {
    setLoading(true);
    return client
      .query({
        query: GET_ORGANIZATION_PRIZES,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const prizesData = data.organizationPrizes;
        setLoading(false);

        return {
          ...prizesData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  return [
    {
      prizeService,
      prizesService,
      createPrizeService,
      updatePrizeService,
      organizationPrizesService
    },
    loading,
  ];
};

export default usePrizeServices;
