import * as React from "react";
import { ModalProps } from "components/UI/Modal/ModalProvider";
import { Modal, Spinner } from "components/UI";
import ModalBody from "components/UI/Modal/ModalBody";
import ModalHeader from "components/UI/Modal/ModalHeader";
import { GeneratePDF, Qr } from "types/Qr";
import ModalFooter from "./ModalFooter";
import useTemplateServices from "services/TemplateService";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress,
} from "@material-ui/core";
import { TemplateElements } from "types/Template";
import useQrServices from "services/QrService";
import { getImg } from "utils/general";

export interface ExtraProps {
  qrObject?: Qr;
  onClose?: () => void;
}

const STEPS = ["Select Template", "Confirm information", "Print"];
const STEP_IDS = {
  SELECT_TEMPLATE: 0,
  CONFIRM_INFORMATION: 1,
  PRINT: 2,
};

const PrintQr: React.FC<ExtraProps & ModalProps> = ({ qrObject, onClose }) => {
  const [{ templatesService, organizationTemplatesService }, templateLoading] =
    useTemplateServices();
  const [{ printQrService }, qrLoading] = useQrServices();
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [generatedPdf, setGeneratedPdf] = React.useState(null);
  const [values, setValues] = React.useState(null);

  const onSubmit = () => {
    const input = {
      uuid: qrObject.uuid,
      templateUUID: selectedTemplate.uuid as string,
      values: values.map((element: TemplateElements) => [
        element.name as string,
        element.value as string,
      ]),
    };

    printQrService({ input }).then((res) => {
      setGeneratedPdf(res.doc?.url);
      setCurrentStep(currentStep + 1);
    });
  };

  React.useEffect(() => {
    getTemplates();
  }, [qrObject]);

  const print = () => {
    window.open(generatedPdf);
  };

  const getTemplates = async () => {
    const res = await templatesService({
      input: {
        page: 1,
        query: {},
      },
    });

    setTemplates(res.docs);
  };

  const renderActions = () => {
    if (currentStep > 0) {
      return (
        <>
          <button
            type="button"
            className="bg-gray-700 text-white px-8 py-2 font-bold rounded-lg"
            onClick={() => setCurrentStep(0)}
          >
            Back
          </button>
          <button
            type="button"
            className="bg-blue-800 text-white px-8 py-2 font-bold rounded-lg"
            onClick={() => {
              if (currentStep == STEPS?.length - 2) onSubmit();
              else {
                print();
              }
            }}
          >
            {currentStep == STEPS?.length - 1 ? "Print" : "Generate"}
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            type="button"
            className="bg-gray-700 text-white px-8 py-2 font-bold rounded-lg"
            onClick={() => onClose()}
          >
            Close
          </button>
          <button
            type="button"
            className="bg-gray-800 text-white px-8 py-2 font-bold rounded-lg disabled:opacity-50"
            disabled={!selectedTemplate}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Next
          </button>
        </>
      );
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case STEP_IDS.SELECT_TEMPLATE:
        return (
          <div className="flex flex-col mx-5">
            <div className="flex flex-col overflow-y-auto max-h-64">
              {templates?.map((template) => (
                <div
                  key={template._id}
                  onClick={() => {
                    setSelectedTemplate(template);
                    setValues(
                      template.templateElements?.filter(
                        (element: TemplateElements) =>
                          element.name && element.type == "text"
                      )
                    );
                  }}
                  className={`flex flex-row items-center justify-between p-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100 ${
                    selectedTemplate?.uuid == template.uuid ? "bg-gray-100" : ""
                  }`}
                >
                  <div className="flex flex-row gap-2 items-center">
                    <img
                      src={getImg(template.previewUrl)}
                      className="w-16 h-18"
                    />
                    <div className="font-bold">{template.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case STEP_IDS.CONFIRM_INFORMATION:
        return (
          <div className="flex flex-col mx-5">
            <div className="font-bold">Template Fields</div>
            <div className="flex flex-col overflow-y-auto max-h-64">
              {!values?.length && (
                <div className="p-8 text-sm">No dynamic fields found</div>
              )}
              {values?.map((element: TemplateElements) => (
                <div className="flex flex-col gap-2 mt-5">
                  <p className="text-xs text-gray-400 capitalize">
                    {element.name}
                  </p>
                  <div>
                    <input
                      id={element.name}
                      name="value"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      value={element.value}
                      onChange={(e) => {
                        const newValues = values.map(
                          (elementR: TemplateElements) => {
                            if (element.name == elementR.name) {
                              return {
                                ...elementR,
                                value: e.target.value,
                              };
                            }
                            return elementR;
                          }
                        );
                        setValues(newValues);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case STEP_IDS.PRINT:
        return (
          <div className="flex flex-col">
            {generatedPdf && (
              <iframe src={generatedPdf} style={{ height: 400 }} />
            )}
          </div>
        );
    }
  };

  return (
    <Modal variant="lg">
      <ModalHeader showCloseButon={true} onClose={onClose}>
        Print Qr: {qrObject?.code}
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col">
          <div className="flex w-full flex-col">
            <Stepper activeStep={currentStep} color="black">
              {STEPS?.map((step) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {templateLoading || qrLoading ? (
            <div className="flex flex-col items-center justify-center h-64">
              <CircularProgress size={32} />
            </div>
          ) : (
            renderStepContent()
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-between w-full">{renderActions()}</div>
      </ModalFooter>
    </Modal>
  );
};

export default PrintQr;
