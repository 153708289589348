import * as React from "react";
import GoogleMapReact from "google-map-react";
import { RAFFLE_LOCATION } from "../../../utils/constants";

const DEFAULT_ZOOM = 11;
const DEFAULT_CENTER = {
  lat: 40.7128,
  lng: -74.006,
};

export interface Location {
  lat: number;
  lng: number;
}

interface MapProps {
  markerPos?: Location;
  showMarker?: boolean;
  zoom?: number;
  defaultCenter?: Location;
  onChange?: (e: Location) => void;
  defaultMarkerIcon?: Boolean;
  readonly?: Boolean;
}

const Map: React.FC<MapProps> = ({
  markerPos,
  showMarker,
  zoom,
  defaultCenter,
  defaultMarkerIcon = false,
  onChange,
  readonly = false,
}: MapProps) => {
  const [mapInstance, setMapInstance] = React.useState(null);
  const [markerInstance, setMarkerInstance] = React.useState(null);

  React.useEffect(() => {
    if (mapInstance && markerInstance && markerPos) {
      markerInstance.setPosition(markerPos);
      mapInstance.panTo(markerPos);
    }
  }, [markerPos]);

  const isLocationValid = (location: Location) => {
    return location.lat && location.lng;
  };

  const handleApiLoaded = (map: any, maps: any) => {
    setMapInstance(map);
    if (!showMarker) return;
    let marker = new maps.Marker({
      position: isLocationValid(markerPos)
        ? markerPos
        : defaultCenter || DEFAULT_CENTER,
      map,
      draggable: !readonly,
      ...(!defaultMarkerIcon ? { icon: RAFFLE_LOCATION } : {}),
    });

    marker.addListener("dragend", function (e: any) {
      onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    });
    setMarkerInstance(marker);
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GOOGLE_MAPS_KEY,
      }}
      defaultZoom={zoom || DEFAULT_ZOOM}
      defaultCenter={defaultCenter || DEFAULT_CENTER}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    ></GoogleMapReact>
  );
};

export default Map;
