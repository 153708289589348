import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { Pagination, PaginationParams } from 'types/Pagination';
import Response from 'types/Response';
import { CreateTeam, GetTeam, Team, UpdateTeam } from 'types/Team';

const GET_TEAMS = gql`
  query teams($input: BasePaginationInput!) {
    teams(input: $input) {
      docs {
        ... on Team {
          uuid
          name
          code
          owner {
            uuid
            username
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_TEAM = gql`
  query team($uuid: String!) {
    team(uuid: $uuid) {
      message
      doc {
        ... on Team {
          uuid
          name
          code
          owner {
            uuid
            username
          }
          users {
            uuid
            username
          }
        }
      }
    }
  }
`;

const CREATE_TEAM = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      doc {
        ... on Team {
          uuid
          name
          owner {
            uuid
            username
          }
        }
      }
      message
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      doc {
        ... on Team {
          uuid
          name
          owner {
            uuid
            username
          }
        }
      }
      message
    }
  }
`;

type UserServices = [services: Services, loading?: boolean];

interface Services {
  teamsService: (input: PaginationParams) => Promise<Pagination<Team>>;
  teamService: (input: GetTeam) => Promise<Response>;
  createTeamService: (input: CreateTeam) => Promise<Response>;
  updateTeamService: (input: UpdateTeam) => Promise<Response>;
}

const useTeamServices = (): UserServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const teamsService = (input: PaginationParams): Promise<Pagination<Team>> => {
    setLoading(true);
    return client
      .query({
        query: GET_TEAMS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const usersData = data.teams;
        setLoading(false);

        return {
          ...usersData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const teamService = ({ uuid }: GetTeam): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_TEAM,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const userData = data.team;
        setLoading(false);

        return {
          ...userData,
        };
      });
  };

  const createTeamService = (input: CreateTeam): Promise<Response> => {
    return client
      .mutate({
        mutation: CREATE_TEAM,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const createTeamData = data.createTeam;

        return {
          ...createTeamData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const updateTeamService = (input: UpdateTeam): Promise<Response> => {
    return client
      .mutate({
        mutation: UPDATE_TEAM,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updateTeamData = data.updateTeam;

        return {
          ...updateTeamData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  return [
    {
      teamsService,
      teamService,
      createTeamService,
      updateTeamService,
    },
    loading,
  ];
};

export default useTeamServices;
