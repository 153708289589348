import { Event } from './Event';
import { Organization } from './Organization';
import { Prize } from './Prize';
import { Qr } from './Qr';
import { Team } from './Team';
import { User } from './User';

export enum RAFFLE_TYPE {
  REGULAR = 'regular',
  MULTIPLE = 'multiple',
}

export enum RAFFLE_STATUS {
  UNCOMMENCED = 'uncommenced',
  ONGOING = 'ongoing',
  DRAWING = 'drawing',
  FINALIZED = 'finalized',
  CANCELLED = 'cancelled',
}

export enum GRAND_PRIZE_STATUS {
  UNLINKED = 'unlinked',
  LINKED = 'linked',
}
interface Location {
  type: string;
  coordinates: number[];
}

export interface Raffle {
  uuid: string;
  title: string;
  description: string;
  status: RAFFLE_STATUS;
  type: RAFFLE_TYPE;
  event: Event;
  organization: Organization;
  startDate: Date;
  endDate: Date;
  location: Location;
  prizes: Prize[];
  qr: Qr;
  active: boolean;
  team: Team;
  settings: RaffleSettings;
  isGrandPrize?: boolean;
  grandPrizeSettings: any;
  participants: User[];
  winners: RaffleWinner[];
}

export interface RaffleWinner {
  user: User;
  pickedUpAt: Date;
  pickUpCode: number;
  selectedAt?: Date;
}

interface RaffleSettings {
  runAt: Date;
  claimSeconds: number;
  winners: number;
  timeZone: string;
  collection: any;
}

export interface CreateRaffle {
  input: {
    status: RAFFLE_STATUS;
    type: RAFFLE_TYPE;
    eventUUID?: string;
    organizationUUID: string;
    startDate: Date;
    endDate: Date;
    location: Location;
    prizes: PrizesInput[];
    qrUUID: string;
    active: boolean;
    teamUUID: string;
    settings: RaffleSettings;
  };
}

export interface UpdateRaffle {
  input: {
    uuid: string;
    title: string;
    description: string;
  }
}

export interface UpdateGrandPrizeSettingsInput {
  input: {
    grandPrizeSettings:{
      status: string
    },
    uuid: string;
  }
}

interface PrizesInput {
  uuid: string;
}

export type GetRaffle = Pick<Raffle, 'uuid'>;
