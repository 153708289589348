import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  cloneElement,
} from 'react';

export const ModalContext = createContext<any>({});
export const useModalContext = () => useContext(ModalContext);

export interface ModalProps {
  children?: React.ReactNode;
  onDismiss?: () => void;
  onSubmit?: (data?: object) => void;
  onReject?: (error?: object) => void;
}

export const ModalProvider: React.FC<ModalProps> = ({ children }) => {
  const [Component, setComponent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (ModalComponent: React.FC) => {
    setComponent(ModalComponent);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        isOpen,
      }}
    >
      {isOpen ? cloneElement(Component) : null}
      {children}
    </ModalContext.Provider>
  );
};

interface UseModal {
  <T extends object = any>(component: React.FC): (args?: T) => Promise<any>;
}

export const useModal: UseModal = (ModalComponent) => {
  const context = useModalContext();
  const { closeModal, openModal } = context;

  const openModalFunc = useCallback((props) => {
    return new Promise((resolve, reject) => {
      const onSubmit = (data: any) => {
        closeModal();
        resolve(data || true);
      };

      const onDismiss = () => {
        closeModal();
        resolve(undefined);
      };

      const onReject = (error: string) => {
        closeModal();
        reject(error);
      };

      openModal(
        <ModalContext.Provider
          value={{
            onDismiss,
            ...context,
          }}
        >
          {cloneElement(<ModalComponent />, {
            onSubmit,
            onDismiss,
            onReject,
            ...props,
          })}
        </ModalContext.Provider>,
      );
    });
  }, []);

  return openModalFunc;
};

export default ModalProvider;
