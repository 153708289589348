import { Organization } from "./Organization";

export enum ROLES {
  USER = 'user',
  ADMIN = 'admin',
  GUEST = 'guest',
  MASTER = 'master',
  ORG = 'org',
}

export enum PERMISSIONS {
  ORGANIZATION = 'organization',
  PLAYER = 'player',
}

export interface User {
  uuid: string;
  username: string;
  email: string;
  active: boolean;
  password: string;
  profile: Profile;
  createdAt: Date;
  roles: ROLES[];
  permissions: PERMISSIONS[];
  organization?: Organization;
  verified: boolean;
}

export interface Profile {
  uuid: string;
  firstname: string;
  lastname: string;
}

export interface RolesPermissions {
  roles: ROLES[];
  permissions: PERMISSIONS[];
}

export interface CreateUser {
  input: {
    username: string;
    active: boolean;
    password: string;
    profile: Omit<Profile, 'uuid'>;
    roles: ROLES[];
    permissions: PERMISSIONS[];
  };
}

export interface UpdateUser {
  input: {
    uuid: string;
    username: string;
    active: boolean;
    password?: string;
    profile: Omit<Profile, 'uuid'>;
    roles: ROLES[];
    permissions: PERMISSIONS[];
  };
}

export type GetUser = Pick<User, 'uuid'>;

export interface UserInput {
  uuid: string;
}