import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/solid';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const Breadcrumb: React.FC = () => {
  const breadcrumbs = useBreadcrumbs();
  const navigation = useNavigate();

  const onNavigate = (path: string) => {
    navigation(path);
  };

  return (
    <div className="flex py-2 px-4">
      {breadcrumbs.map(({ breadcrumb, key, location }, index) => {
        return (
          <div
            key={key}
            className="inline-flex items-center text-gray-700 text-sm font-medium"
          >
            {index === 0 && <HomeIcon className="w-6 h-6 mx-auto sm:-mx-0" />}
            <span
              className={`px-2 ${index > 0 ? 'cursor-pointer' : 'cursor-auto'}`}
              onClick={() => onNavigate(key)}
            >
              {breadcrumb}
            </span>
            {index < breadcrumbs.length - 1 && (
              <div className="flex items-center">
                <svg
                  className="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
