import * as React from "react";

import Table from "components/UI/Table";
import { useNavigate } from "react-router";
import { ROUTE_DEFINITIONS } from "types/Routes";
import { replaceParam } from "routing";
import { Qr } from "types/Qr";
import useQrServices from "services/QrService";
import { useAuth } from "AuthProvider";
import { ROLES } from "types/User";
import { PrinterIcon } from "@heroicons/react/outline";
import { Modal } from "components/UI";
import PrintQr from "components/UI/Modal/PrintQr";

const QrList: React.FC = () => {
  const navigate = useNavigate();
  const [{ qrsService, organizationQrsService }] = useQrServices();
  const [limit, setLimit] = React.useState<number>(25);
  const { session } = useAuth();
  const [selectedQr, setSelectedQr] = React.useState(null);
  const [showPrintDialog, setShowPrintDialog] = React.useState(false);

  const onNewQrHanlder = async () => {
    navigate(ROUTE_DEFINITIONS.NEW_QR.path);
  };

  const onEditQrHandler = async (qr: Qr) => {
    navigate(replaceParam(ROUTE_DEFINITIONS.EDIT_QR.path, "uuid", qr.uuid));
  };

  return (
    <div className="h-full w-full">
      {showPrintDialog && (
        <PrintQr
          qrObject={selectedQr}
          onClose={() => {
            setSelectedQr(null);
            setShowPrintDialog(false);
          }}
        />
      )}
      <Table
        title="Qr list"
        limit={limit}
        columns={[
          { title: "Code", field: "code" },
          { title: "Type", field: "type" },
          {
            title: "Assigned",
            render: (rowData: Qr) => (rowData.organization ? "YES" : "NO"),
          },
          {
            title: "Org",
            render: (rowData: Qr) => rowData.organization?.name || "N/A",
          },
        ]}
        data={async (query: any) => {
          const isOrg = session?.user?.roles?.includes(ROLES.ORG);
          const service = isOrg ? organizationQrsService : qrsService;
          const res = await service({
            input: {
              page: query.page + 1,
              query: {
                code: {
                  type: "regex",
                  value: query.search,
                },
              },
            },
          });

          setLimit(res.totalDocs > res.limit ? res.limit : res.totalDocs);

          return {
            data: res.docs,
            page: res.page - 1,
            totalCount: res.totalDocs,
          };
        }}
        actions={[
          (_) => ({
            icon: "edit",
            tooltip: "Edit Qr",
            onClick: (_, rowData: Qr) => onEditQrHandler(rowData),
          }),
          (_) => ({
            icon: "print",
            tooltip: "Print qr",
            onClick: (_, rowData: Qr) => {
              setSelectedQr(rowData);
              setShowPrintDialog(true);
            },
          }),
          {
            icon: "add",
            tooltip: "Add Qr",
            isFreeAction: true,
            onClick: onNewQrHanlder,
          },
        ]}
      />
    </div>
  );
};

export default QrList;
