import React from "react";
import { gql, useApolloClient } from "@apollo/client";
import { deepCopy } from "libs";
import { Pagination, PaginationParams } from "types/Pagination";
import Response from "types/Response";
import {
  CreateTemplate,
  GetTemplate,
  Template,
  UpdateTemplate,
} from "types/Template";

const GET_TEMPLATES = gql`
  query templates($input: BasePaginationInput!) {
    templates(input: $input) {
      docs {
        ... on Template {
          uuid
          backgroundUrl
          title
          default
          previewUrl
          templateElements {
            id
            name
            type
            value
            width
            height
            top
            left
            rotateAngle
            fontSize
            qrColor
            qrBgColor
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_ORGANIZATION_TEMPLATES = gql`
  query organizationTemplates($input: BasePaginationInput!) {
    organizationTemplates(input: $input) {
      docs {
        ... on Template {
          uuid
          backgroundUrl
          title
          default
          previewUrl
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_TEMPLATE = gql`
  query template($uuid: String!) {
    template(uuid: $uuid) {
      message
      doc {
        ... on Template {
          uuid
          backgroundUrl
          title
          default
          templateElements {
            id
            name
            type
            value
            width
            height
            top
            left
            rotateAngle
            fontSize
            qrColor
            qrBgColor
          }
          qrs {
            uuid
            code
          }
          previewUrl
        }
      }
    }
  }
`;

const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input) {
      doc {
        ... on Template {
          uuid
          backgroundUrl
          title
          default
          templateElements {
            id
            name
            type
            value
            width
            height
            top
            left
            rotateAngle
            fontSize
          }
          qrs {
            uuid
            code
          }
        }
      }
      message
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      doc {
        ... on Template {
          uuid
          backgroundUrl
          title
          default
          templateElements {
            id
            name
            type
            value
            width
            height
            top
            left
            rotateAngle
            fontSize
          }
          qrs {
            uuid
            code
          }
        }
      }
      message
    }
  }
`;

const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($uuid: String!) {
    deleteTemplate(uuid: $uuid) {
      message
    }
  }
`;

  

type TemplateServices = [services: Services, loading?: boolean];

interface Services {
  templatesService: (input: PaginationParams) => Promise<Pagination<Template>>;
  templateService: (input: GetTemplate) => Promise<Response>;
  createTemplateService: (input: CreateTemplate) => Promise<Response>;
  updateTemplateService: (input: UpdateTemplate) => Promise<Response>;
  organizationTemplatesService: (
    input: PaginationParams
  ) => Promise<Pagination<Template>>;
  deleteTemplateService: (input: string) => Promise<Response>;
}

const useTemplateServices = (): TemplateServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const templatesService = (
    input: PaginationParams
  ): Promise<Pagination<Template>> => {
    setLoading(true);
    return client
      .query({
        query: GET_TEMPLATES,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const templatesData = data.templates;
        setLoading(false);

        return {
          ...templatesData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const organizationTemplatesService = (
    input: PaginationParams
  ): Promise<Pagination<Template>> => {
    setLoading(true);
    return client
      .query({
        query: GET_ORGANIZATION_TEMPLATES,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const templatesData = data.organizationTemplates;
        setLoading(false);

        return {
          ...templatesData,
        };
      })
      .catch(() => ({ docs: [] }));
  };

  const templateService = ({ uuid }: GetTemplate): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_TEMPLATE,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const templateData = data.template;
        setLoading(false);

        return {
          ...templateData,
        };
      });
  };

  const createTemplateService = (input: CreateTemplate): Promise<Response> => {
    return client
      .mutate({
        context: {
          hasUpload: true,
        },
        mutation: CREATE_TEMPLATE,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const createTemplateData = data.createTemplate;

        return {
          ...createTemplateData,
        };
      })
      .catch((err) => ({
        error: err,
      }));
  };

  const deleteTemplateService = (uuid: string): Promise<Response> => {
    setLoading(true);
    return client
      .mutate({
        context: {
          hasUpload: true,
        },
        mutation: DELETE_TEMPLATE,
        variables: {
          uuid,
        },
      })
      .then(({ data }) => {
        const deleteTemplateData = data.deleteTemplate;
        return {
          ...deleteTemplateData,
        };
      })
      .catch((err) => ({
        error: err,
      })).finally(() => setLoading(false));
  };

  const updateTemplateService = (input: UpdateTemplate): Promise<Response> => {
    setLoading(true);
    return client
      .mutate({
        context: {
          hasUpload: true,
        },
        mutation: UPDATE_TEMPLATE,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updateTemplateData = data.updateTemplate;

        return {
          ...updateTemplateData,
        };
      })
      .catch((err) => ({
        error: err,
      }))
      .finally(() => setLoading(false));
  };

  return [
    {
      templatesService,
      templateService,
      createTemplateService,
      updateTemplateService,
      organizationTemplatesService,
      deleteTemplateService
    },
    loading,
  ];
};

export default useTemplateServices;
