export const LOGO_ICON =
  "https://firebasestorage.googleapis.com/v0/b/rafflehunter-app.appspot.com/o/assets%2Flogos%2Ficon.png?alt=media&token=db949618-12a0-45ca-85f0-59ce7b8a8b9d";

export const LOGO_WHITE =
  "https://firebasestorage.googleapis.com/v0/b/rafflehunter-app.appspot.com/o/assets%2Flogos%2Flogo-white.png?alt=media&token=a13c5ff6-fd4e-4b4b-907b-dc7a9f497a39";

export const LOGO_ORANGE =
  "https://firebasestorage.googleapis.com/v0/b/rafflehunter-app.appspot.com/o/assets%2Flogos%2Flogo-orange.png?alt=media&token=07b6feba-4776-4510-bc22-a8e16f9c723b";

export const RAFFLE_LOCATION =
  "https://firebasestorage.googleapis.com/v0/b/rafflehunter-app.appspot.com/o/assets%2Flogos%2Fraffle-locations.png?alt=media&token=8532cb8b-ca94-41c0-b427-40daff490eb0";

export const DEV_MODE = "development";
export const PROD_MODE = "production";
