import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { deepCopy } from 'libs';
import { Pagination, PaginationParams } from 'types/Pagination';
import Response from 'types/Response';
import {
  CreateOrganization,
  GetOrganization,
  Organization,
  UpdateOrganization,
} from 'types/Organization';

const GET_ORGANIZATIONS = gql`
  query organizations($input: BasePaginationInput!) {
    organizations(input: $input) {
      docs {
        ... on Organization {
          uuid
          name
          details
          settings {
            allowGrandPrizeRaffleCreation
            authGrandPrizeLinkAuto
          }
          location {
            coordinates
          }
          user {
            uuid
            username
          }
        }
      }
      hasPrevPage
      hasNextPage
      page
      totalPages
      totalDocs
      limit
    }
  }
`;

const GET_ORG_STATS = gql`
  query organizationStats {
    organizationStats {
      doc
      message
    }
  }
`;

const GET_ORGANIZATION = gql`
  query organization($uuid: String!) {
    organization(uuid: $uuid) {
      message
      doc {
        ... on Organization {
          uuid
          name
          details
          settings {
            allowGrandPrizeRaffleCreation
            authGrandPrizeLinkAuto
          }
          location {
            coordinates
          }
          user {
            uuid
            username
          }
        }
      }
    }
  }
`;

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      doc {
        ... on Organization {
          uuid
          name
          details
          settings {
            allowGrandPrizeRaffleCreation
            authGrandPrizeLinkAuto
          }
          location {
            coordinates
          }
          user {
            uuid
            username
          }
        }
      }
      message
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      doc {
        ... on Organization {
          uuid
          name
          details
          settings {
            allowGrandPrizeRaffleCreation
            authGrandPrizeLinkAuto
          }
          location {
            coordinates
          }
          user {
            uuid
            username
          }
        }
      }
      message
    }
  }
`;

type OrganizationServices = [services: Services, loading?: boolean];

interface Services {
  organizationsService: (
    input: PaginationParams,
  ) => Promise<Pagination<Organization>>;
  organizationService: (input: GetOrganization) => Promise<Response>;
  createOriganizationService: (input: CreateOrganization) => Promise<Response>;
  updateOriganizationService: (input: UpdateOrganization) => Promise<Response>;
  organizationStatsService: () => Promise<Response>;
}

const useOrganizationServices = (): OrganizationServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const organizationsService = (
    input: PaginationParams,
  ): Promise<Pagination<Organization>> => {
    setLoading(true);
    return client
      .query({
        query: GET_ORGANIZATIONS,
        variables: {
          ...input,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const organizationsData = data.organizations;
        setLoading(false);

        return {
          ...organizationsData,
        };
      });
  };

  const organizationStatsService = (
  ): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_ORG_STATS,
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const organizationsData = data.organizationStats;
        setLoading(false);

        return {
          ...organizationsData,
        };
      });
  };

  const organizationService = ({
    uuid,
  }: GetOrganization): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_ORGANIZATION,
        variables: {
          uuid,
        },
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const organizationData = data.organization;
        setLoading(false);

        return {
          ...organizationData,
        };
      });
  };

  const createOriganizationService = (
    input: CreateOrganization,
  ): Promise<Response> => {
    setLoading(true);
    return client
      .mutate({
        mutation: CREATE_ORGANIZATION,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const createOriganizationData = data.createOriganization;

        return {
          ...createOriganizationData,
        };
      })
      .catch((err) => ({
        error: err,
      }))
      .finally(() => setLoading(false));
  };

  const updateOriganizationService = (
    input: UpdateOrganization,
  ): Promise<Response> => {
    setLoading(true);
    return client
      .mutate({
        mutation: UPDATE_ORGANIZATION,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updateOriganizationData = data.updateOrganization;

        return {
          ...updateOriganizationData,
        };
      })
      .catch((err) => ({
        error: err,
      }))
      .finally(() => setLoading(false));

  };

  return [
    {
      organizationsService,
      organizationService,
      createOriganizationService,
      updateOriganizationService,
      organizationStatsService
    },
    loading,
  ];
};

export default useOrganizationServices;
