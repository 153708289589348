import React from "react";
import { gql, useApolloClient } from "@apollo/client";
import { deepCopy } from "libs";
import Response from "types/Response";
import { Settings, UpdateSettings } from "types/Settings";

const GET_SETTINGS = gql`
  query Settings {
    settings {
      doc
      message
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation Mutation($input: UpdateSettingsInput!) {
    updateSettings(input: $input) {
      doc
      message
    }
  }
`;

type SettingsServices = [services: Services, loading?: boolean];

interface Services {
  settingsService: () => Promise<Response>;
  updateSettingsService: (input: UpdateSettings) => Promise<Response>;
}

const useSettingsService = (): SettingsServices => {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState<boolean>(false);

  const settingsService = (): Promise<Response> => {
    setLoading(true);
    return client
      .query({
        query: GET_SETTINGS,
      })
      .then((res) => deepCopy(res))
      .then(({ data }): any => {
        const result = data.settings;
        return result;
      })
      .finally(() => setLoading(false));
  };

  const updateSettingsService = (input: UpdateSettings): Promise<Response> => {
    return client
      .mutate({
        mutation: UPDATE_SETTINGS,
        variables: {
          ...input,
        },
      })
      .then(({ data }) => {
        const updatedSettings = data.updateSettings;

        return {
          ...updatedSettings,
        };
      })
      .catch((err) => ({
        error: err,
      }))
      .finally(() => setLoading(false));
  };

  return [
    {
      settingsService,
      updateSettingsService,
    },
    loading,
  ];
};

export default useSettingsService;
