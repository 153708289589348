import * as React from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_DEFINITIONS } from 'types/Routes';
import { toast } from 'react-toastify';
import usePrizeServices from 'services/PrizeService';
import { Organization } from 'types/Organization';
import { Prize } from 'types/Prize';
import useOrganizationServices from 'services/OrganizationService';
import FileUpload from 'components/UI/FileUpload';

const ManagePrize: React.FC = () => {
  const { uuid } = useParams();
  const navigation = useNavigate();
  const [
    { prizeService, createPrizeService, updatePrizeService },
    prizeServieLoading,
  ] = usePrizeServices();
  const [{ organizationsService }, organizationsServieLoading] =
    useOrganizationServices();
  const [organization, setOrganization] =
    React.useState<{ label: string; value: Organization }>(null);
  const [prize, setPrize] = React.useState<Prize>(null);
  const [file, setFile] = React.useState<File>(null);

  const fetchPrize = React.useCallback(async () => {
    if (uuid) {
      const { doc } = await prizeService({ uuid });
      const t = doc as Prize;

      setPrize(t);
      setOrganization({ label: t.organization.name, value: t.organization });
    }
  }, [uuid]);

  React.useEffect(() => {
    fetchPrize();
  }, [fetchPrize]);

  const onSubmitHandler = async () => {
    const input = {
      title: prize.title,
      value: parseFloat(`${prize.value}`),
      organizationUUID: organization.value.uuid,
      file,
    };
    let res;

    if (!uuid) {
      res = await createPrizeService({ input });
      navigation(ROUTE_DEFINITIONS.PRIZE.path);
    } else {
      res = await updatePrizeService({
        input: {
          ...input,
          uuid: prize.uuid,
        },
      });
    }

    toast.success(res.message);
  };

  const onInputChange = (e: React.SyntheticEvent) => {
    const data = e.currentTarget as HTMLInputElement;
    const { name, value } = data;

    setPrize({
      ...prize,
      [name]: value,
    });
  };

  const loadOrganizations = React.useCallback(
    debounce(
      (query: string) =>
        organizationsService({
          input: {
            page: 1,
            query,
          },
        }).then(({ docs }) => {
          return docs.map((organization: Organization) => ({
            label: organization.name,
            value: organization,
          }));
        }),
      500,
    ),
    [],
  );

  const onFileChange = (file: File) => setFile(file);

  return (
    <div className="flex flex-col bg-white rounded h-full">
      <div className="w-full sm:w-2/3 p-4 mx-auto space-y-6">
        <h1 className="text-2xl capitalize">
          {uuid ? 'Edit prize' : 'New prize'}
        </h1>
        <div className="px-2">
          <div className="flex flex-col w-full h-full">
            <form
              className="flex flex-col space-y-6"
              onSubmit={async (e) => {
                e.preventDefault();
                await onSubmitHandler();
              }}
            >
              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Prize information
                </p>
                <div className="rounded shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="title" className="sr-only">
                      Title
                    </label>
                    <input
                      required
                      id="title"
                      name="title"
                      type="text"
                      value={prize?.title ?? ''}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Title"
                      onChange={onInputChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="value" className="sr-only">
                      Value
                    </label>
                    <input
                      id="value"
                      name="value"
                      type="number"
                      value={prize?.value ?? ''}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                      placeholder="Value"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">
                  Prize organization
                </p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="organization" className="sr-only">
                      Organization
                    </label>
                    <AsyncSelect
                      id="organization"
                      name="organization"
                      placeholder="Organization"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={organization}
                      loadOptions={loadOrganizations}
                      onChange={(organization: {
                        label: string;
                        value: Organization;
                      }) => setOrganization(organization)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-xs text-gray-400 capitalize">Prize image</p>
                <div className="flex flex-col">
                  <div>
                    <label htmlFor="organization" className="sr-only">
                      Image
                    </label>
                    <FileUpload
                      title="Upload Image(png,jpeg)"
                      placeholder="Select a photo"
                      variant="image"
                      onFileChange={onFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2 flex rounded shadow-sm ml-auto">
                <button
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-500 rounded-l-lg hover:bg-gray-600"
                  onClick={() => navigation(ROUTE_DEFINITIONS.PRIZE.path)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={prizeServieLoading || organizationsServieLoading}
                  className="w-1/2 py-2 px-4 text-base text-white bg-gray-800 rounded-r-md hover:bg-gray-900"
                >
                  Accept
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePrize;
