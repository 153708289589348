import * as React from "react";
import MaterialTable, {
  Action,
  Column,
  Query,
  QueryResult,
} from "material-table";

interface TableProps {
  title?: string;
  limit?: number;
  height?: string;
  actions?: (Action<any> | ((rowData: any) => Action<any>))[];
  columns: Column<any>[];
  data?: any[] | ((query: Query<any>) => Promise<QueryResult<any>>);
}

const Table: React.ForwardRefRenderFunction<any, TableProps> = (
  { title, limit, columns, actions, height, data },
  ref
) => {
  return (
    <MaterialTable
      tableRef={ref}
      options={{
        search: true,
        debounceInterval: 500,
        pageSize: limit,
        pageSizeOptions: [],
        actionsColumnIndex: -1,
        sorting: false,
        headerStyle: {
          position: "sticky",
          top: 0,
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "#4b5563",
          color: "#e3e6ea",
        },
        rowStyle: {
          paddingLeft: "1rem",
          paddingRight: "1rem",
        },
        minBodyHeight: height || "calc(100vh - 250px)",
        maxBodyHeight: height || "calc(100vh - 250px)",
      }}
      localization={{
        header: {
          actions: "Actions",
        },
      }}
      title={title}
      columns={columns}
      data={data}
      actions={actions}
    />
  );
};

export default React.forwardRef(Table);
